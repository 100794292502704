import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../context/globalContext";
import { createReceiptPDF } from "../../util/pdfRelated";
import { getUserOrdersAPI } from "../../api/user";
import getSymbolFromCurrency from "currency-symbol-map";

interface LineItem {
  title: string;
  price: number;
  quantity: number;
}

interface Order {
  processedAt: string;
  financialStatus: string;
  totalPrice: number;
  totalTax: number;
  orderNumber: string;
  lineItems: LineItem[];
}

interface ReceiptLineItem {
  name: string;
  quantity: string;
  unitPriceIncl: string;
  subtotal: string;
}

const emailAddress = { tech: "contact@eventshouse.net" };

export default function UserPageOrders() {
  // const [orders, setOrders] = useState<any[]>([
  //   {
  //     id: 5304899404075,
  //     admin_graphql_api_id: "gid://shopify/Order/5304899404075",
  //     app_id: 88312,
  //     browser_ip: "209.35.89.182",
  //     buyer_accepts_marketing: false,
  //     cancel_reason: null,
  //     cancelled_at: null,
  //     cart_token: null,
  //     checkout_id: 36659120800043,
  //     checkout_token: "a6d3af0760024546f9ca187495618b1c",
  //     client_details: {
  //       accept_language: "en",
  //       browser_height: null,
  //       browser_ip: "209.35.89.182",
  //       browser_width: null,
  //       session_hash: null,
  //       user_agent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36",
  //     },
  //     closed_at: "2023-05-24T19:25:33+01:00",
  //     confirmation_number: "MJ6DGNFJ5",
  //     confirmed: true,
  //     created_at: "2023-04-12T13:41:18+01:00",
  //     currency: "GBP",
  //     current_subtotal_price: "1.00",
  //     current_subtotal_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     current_total_additional_fees_set: null,
  //     current_total_discounts: "0.00",
  //     current_total_discounts_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     current_total_duties_set: null,
  //     current_total_price: "1.00",
  //     current_total_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     current_total_tax: "0.00",
  //     current_total_tax_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     customer_locale: "en",
  //     device_id: null,
  //     discount_codes: [],
  //     estimated_taxes: false,
  //     financial_status: "paid",
  //     fulfillment_status: null,
  //     landing_site: "/checkouts/co/cd9f3bd66da47c995640edcb2bc26df2",
  //     landing_site_ref: null,
  //     location_id: null,
  //     merchant_of_record_app_id: null,
  //     name: "#2648131021",
  //     note: null,
  //     note_attributes: [],
  //     number: 21,
  //     order_number: 1021,
  //     original_total_additional_fees_set: null,
  //     original_total_duties_set: null,
  //     payment_gateway_names: ["shopify_payments"],
  //     po_number: null,
  //     presentment_currency: "GBP",
  //     processed_at: "2023-04-12T13:41:14+01:00",
  //     reference: "af1626118ba9fd42618d3bf91ebbddc4",
  //     referring_site: "http://localhost:3000/",
  //     source_identifier: "af1626118ba9fd42618d3bf91ebbddc4",
  //     source_name: "88312",
  //     source_url: null,
  //     subtotal_price: "1.00",
  //     subtotal_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     tags: "",
  //     tax_exempt: false,
  //     tax_lines: [],
  //     taxes_included: true,
  //     test: false,
  //     token: "0ef85e57c1e0f062f375fa006720c8fc",
  //     total_discounts: "0.00",
  //     total_discounts_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_line_items_price: "1.00",
  //     total_line_items_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_outstanding: "0.00",
  //     total_price: "1.00",
  //     total_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_shipping_price_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_tax: "0.00",
  //     total_tax_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_tip_received: "0.00",
  //     total_weight: 0,
  //     updated_at: "2024-04-11T08:26:10+01:00",
  //     user_id: null,
  //     billing_address: {
  //       province: "England",
  //       country: "United Kingdom",
  //       country_code: "GB",
  //       province_code: "ENG",
  //     },
  //     customer: {
  //       id: 7803543290155,
  //       created_at: "2023-04-12T12:08:03+01:00",
  //       updated_at: "2024-04-11T08:26:10+01:00",
  //       state: "enabled",
  //       note: null,
  //       verified_email: true,
  //       multipass_identifier: null,
  //       tax_exempt: false,
  //       email_marketing_consent: {
  //         state: "not_subscribed",
  //         opt_in_level: "single_opt_in",
  //         consent_updated_at: null,
  //       },
  //       sms_marketing_consent: null,
  //       tags: "",
  //       currency: "GBP",
  //       tax_exemptions: [],
  //       admin_graphql_api_id: "gid://shopify/Customer/7803543290155",
  //       default_address: {
  //         id: 9148695576875,
  //         customer_id: 7803543290155,
  //         company: null,
  //         province: "England",
  //         country: "United Kingdom",
  //         province_code: "ENG",
  //         country_code: "GB",
  //         country_name: "United Kingdom",
  //         default: true,
  //       },
  //     },
  //     discount_applications: [],
  //     fulfillments: [],
  //     line_items: [
  //       {
  //         id: 13814117073195,
  //         admin_graphql_api_id: "gid://shopify/LineItem/13814117073195",
  //         attributed_staffs: [],
  //         current_quantity: 1,
  //         fulfillable_quantity: 1,
  //         fulfillment_service: "manual",
  //         fulfillment_status: null,
  //         gift_card: false,
  //         grams: 0,
  //         name: "Listener - Student Non-member (Standard)",
  //         price: "1.00",
  //         price_set: {
  //           shop_money: {
  //             amount: "1.00",
  //             currency_code: "GBP",
  //           },
  //           presentment_money: {
  //             amount: "1.00",
  //             currency_code: "GBP",
  //           },
  //         },
  //         product_exists: true,
  //         product_id: 8154617381163,
  //         properties: [],
  //         quantity: 1,
  //         requires_shipping: false,
  //         sku: "",
  //         taxable: false,
  //         title: "Listener - Student Non-member (Standard)",
  //         total_discount: "0.00",
  //         total_discount_set: {
  //           shop_money: {
  //             amount: "0.00",
  //             currency_code: "GBP",
  //           },
  //           presentment_money: {
  //             amount: "0.00",
  //             currency_code: "GBP",
  //           },
  //         },
  //         variant_id: 44634807501099,
  //         variant_inventory_management: null,
  //         variant_title: null,
  //         vendor: "IEEE SWC2023",
  //         tax_lines: [],
  //         duties: [],
  //         discount_allocations: [],
  //       },
  //     ],
  //     payment_terms: null,
  //     refunds: [],
  //     shipping_address: null,
  //     shipping_lines: [],
  //   },
  //   {
  //     id: 5304895897899,
  //     admin_graphql_api_id: "gid://shopify/Order/5304895897899",
  //     app_id: 88312,
  //     browser_ip: "209.35.89.182",
  //     buyer_accepts_marketing: false,
  //     cancel_reason: "customer",
  //     cancelled_at: "2023-04-12T13:37:00+01:00",
  //     cart_token: null,
  //     checkout_id: 36659111985451,
  //     checkout_token: "e22ff9a78ba6c5b6e847636f5396db22",
  //     client_details: {
  //       accept_language: "en",
  //       browser_height: null,
  //       browser_ip: "209.35.89.182",
  //       browser_width: null,
  //       session_hash: null,
  //       user_agent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36",
  //     },
  //     closed_at: "2023-04-12T13:37:00+01:00",
  //     confirmation_number: "A1TYVTP5M",
  //     confirmed: true,
  //     created_at: "2023-04-12T13:34:10+01:00",
  //     currency: "GBP",
  //     current_subtotal_price: "0.00",
  //     current_subtotal_price_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     current_total_additional_fees_set: null,
  //     current_total_discounts: "0.00",
  //     current_total_discounts_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     current_total_duties_set: null,
  //     current_total_price: "0.00",
  //     current_total_price_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     current_total_tax: "0.00",
  //     current_total_tax_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     customer_locale: "en",
  //     device_id: null,
  //     discount_codes: [],
  //     estimated_taxes: false,
  //     financial_status: "voided",
  //     fulfillment_status: null,
  //     landing_site: "/checkouts/co/cd9f3bd66da47c995640edcb2bc26df2",
  //     landing_site_ref: null,
  //     location_id: null,
  //     merchant_of_record_app_id: null,
  //     name: "#2648131020",
  //     note: null,
  //     note_attributes: [],
  //     number: 20,
  //     order_number: 1020,
  //     original_total_additional_fees_set: null,
  //     original_total_duties_set: null,
  //     payment_gateway_names: ["WeChat or Alipay"],
  //     po_number: null,
  //     presentment_currency: "GBP",
  //     processed_at: "2023-04-12T13:34:08+01:00",
  //     reference: "60c18b18b6387cfea4ebb01f937cfd5c",
  //     referring_site: "http://localhost:3000/",
  //     source_identifier: "60c18b18b6387cfea4ebb01f937cfd5c",
  //     source_name: "88312",
  //     source_url: null,
  //     subtotal_price: "1.00",
  //     subtotal_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     tags: "",
  //     tax_exempt: false,
  //     tax_lines: [],
  //     taxes_included: true,
  //     test: false,
  //     token: "7474bee2fb99621dff6b7c735ea10390",
  //     total_discounts: "0.00",
  //     total_discounts_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_line_items_price: "1.00",
  //     total_line_items_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_outstanding: "0.00",
  //     total_price: "1.00",
  //     total_price_set: {
  //       shop_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "1.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_shipping_price_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_tax: "0.00",
  //     total_tax_set: {
  //       shop_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //       presentment_money: {
  //         amount: "0.00",
  //         currency_code: "GBP",
  //       },
  //     },
  //     total_tip_received: "0.00",
  //     total_weight: 0,
  //     updated_at: "2024-04-11T08:26:10+01:00",
  //     user_id: null,
  //     billing_address: {
  //       province: "England",
  //       country: "United Kingdom",
  //       country_code: "GB",
  //       province_code: "ENG",
  //     },
  //     customer: {
  //       id: 7803543290155,
  //       created_at: "2023-04-12T12:08:03+01:00",
  //       updated_at: "2024-04-11T08:26:10+01:00",
  //       state: "enabled",
  //       note: null,
  //       verified_email: true,
  //       multipass_identifier: null,
  //       tax_exempt: false,
  //       email_marketing_consent: {
  //         state: "not_subscribed",
  //         opt_in_level: "single_opt_in",
  //         consent_updated_at: null,
  //       },
  //       sms_marketing_consent: null,
  //       tags: "",
  //       currency: "GBP",
  //       tax_exemptions: [],
  //       admin_graphql_api_id: "gid://shopify/Customer/7803543290155",
  //       default_address: {
  //         id: 9148695576875,
  //         customer_id: 7803543290155,
  //         company: null,
  //         province: "England",
  //         country: "United Kingdom",
  //         province_code: "ENG",
  //         country_code: "GB",
  //         country_name: "United Kingdom",
  //         default: true,
  //       },
  //     },
  //     discount_applications: [],
  //     fulfillments: [],
  //     line_items: [
  //       {
  //         id: 13814111928619,
  //         admin_graphql_api_id: "gid://shopify/LineItem/13814111928619",
  //         attributed_staffs: [],
  //         current_quantity: 0,
  //         fulfillable_quantity: 0,
  //         fulfillment_service: "manual",
  //         fulfillment_status: null,
  //         gift_card: false,
  //         grams: 0,
  //         name: "Listener - Student Non-member (Standard)",
  //         price: "1.00",
  //         price_set: {
  //           shop_money: {
  //             amount: "1.00",
  //             currency_code: "GBP",
  //           },
  //           presentment_money: {
  //             amount: "1.00",
  //             currency_code: "GBP",
  //           },
  //         },
  //         product_exists: true,
  //         product_id: 8154617381163,
  //         properties: [],
  //         quantity: 1,
  //         requires_shipping: false,
  //         sku: "",
  //         taxable: false,
  //         title: "Listener - Student Non-member (Standard)",
  //         total_discount: "0.00",
  //         total_discount_set: {
  //           shop_money: {
  //             amount: "0.00",
  //             currency_code: "GBP",
  //           },
  //           presentment_money: {
  //             amount: "0.00",
  //             currency_code: "GBP",
  //           },
  //         },
  //         variant_id: 44634807501099,
  //         variant_inventory_management: null,
  //         variant_title: null,
  //         vendor: "IEEE SWC2023",
  //         tax_lines: [],
  //         duties: [],
  //         discount_allocations: [],
  //       },
  //     ],
  //     payment_terms: null,
  //     refunds: [
  //       {
  //         id: 946050007339,
  //         admin_graphql_api_id: "gid://shopify/Refund/946050007339",
  //         created_at: "2023-04-12T13:36:59+01:00",
  //         note: null,
  //         order_id: 5304895897899,
  //         processed_at: "2023-04-12T13:36:59+01:00",
  //         restock: true,
  //         total_duties_set: {
  //           shop_money: {
  //             amount: "0.00",
  //             currency_code: "GBP",
  //           },
  //           presentment_money: {
  //             amount: "0.00",
  //             currency_code: "GBP",
  //           },
  //         },
  //         user_id: 93248946475,
  //         order_adjustments: [],
  //         transactions: [
  //           {
  //             id: 6483778142507,
  //             admin_graphql_api_id:
  //               "gid://shopify/OrderTransaction/6483778142507",
  //             amount: "0.00",
  //             authorization: null,
  //             created_at: "2023-04-12T13:36:59+01:00",
  //             currency: "GBP",
  //             device_id: null,
  //             error_code: null,
  //             gateway: "WeChat or Alipay",
  //             kind: "void",
  //             location_id: null,
  //             message: "Marked the WeChat or Alipay payment as voided",
  //             order_id: 5304895897899,
  //             parent_id: 6483776471339,
  //             payment_id: "c36659111985451.1",
  //             processed_at: "2023-04-12T13:36:59+01:00",
  //             receipt: {},
  //             source_name: "1830279",
  //             status: "success",
  //             test: false,
  //             user_id: null,
  //           },
  //         ],
  //         refund_line_items: [
  //           {
  //             id: 555385258283,
  //             line_item_id: 13814111928619,
  //             location_id: 79557919019,
  //             quantity: 1,
  //             restock_type: "cancel",
  //             subtotal: 1,
  //             subtotal_set: {
  //               shop_money: {
  //                 amount: "1.00",
  //                 currency_code: "GBP",
  //               },
  //               presentment_money: {
  //                 amount: "1.00",
  //                 currency_code: "GBP",
  //               },
  //             },
  //             total_tax: 0,
  //             total_tax_set: {
  //               shop_money: {
  //                 amount: "0.00",
  //                 currency_code: "GBP",
  //               },
  //               presentment_money: {
  //                 amount: "0.00",
  //                 currency_code: "GBP",
  //               },
  //             },
  //             line_item: {
  //               id: 13814111928619,
  //               admin_graphql_api_id: "gid://shopify/LineItem/13814111928619",
  //               attributed_staffs: [],
  //               current_quantity: 0,
  //               fulfillable_quantity: 0,
  //               fulfillment_service: "manual",
  //               fulfillment_status: null,
  //               gift_card: false,
  //               grams: 0,
  //               name: "Listener - Student Non-member (Standard)",
  //               price: "1.00",
  //               price_set: {
  //                 shop_money: {
  //                   amount: "1.00",
  //                   currency_code: "GBP",
  //                 },
  //                 presentment_money: {
  //                   amount: "1.00",
  //                   currency_code: "GBP",
  //                 },
  //               },
  //               product_exists: true,
  //               product_id: 8154617381163,
  //               properties: [],
  //               quantity: 1,
  //               requires_shipping: false,
  //               sku: "",
  //               taxable: false,
  //               title: "Listener - Student Non-member (Standard)",
  //               total_discount: "0.00",
  //               total_discount_set: {
  //                 shop_money: {
  //                   amount: "0.00",
  //                   currency_code: "GBP",
  //                 },
  //                 presentment_money: {
  //                   amount: "0.00",
  //                   currency_code: "GBP",
  //                 },
  //               },
  //               variant_id: 44634807501099,
  //               variant_inventory_management: null,
  //               variant_title: null,
  //               vendor: "IEEE SWC2023",
  //               tax_lines: [],
  //               duties: [],
  //               discount_allocations: [],
  //             },
  //           },
  //         ],
  //         duties: [],
  //       },
  //     ],
  //     shipping_address: null,
  //     shipping_lines: [],
  //   },
  // ]);
  const [orders, setOrders] = useState<Order[]>([]);

  //   const [orders, setOrders] = useState<any[]>([]);
  const { currentUser } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        setIsLoading(true);
        setError(null);
        try {
          const data = await getUserOrdersAPI();
          const orders = data.orders.map((order: any) => ({
            ...order,
            billingAddress: JSON.parse(order.billingAddress),
            lineItems: JSON.parse(order.lineItems),
          }));
          setOrders(orders);
        } catch (err) {
          console.error("Error fetching orders:", err);
          setError("Failed to fetch orders. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchOrders();
  }, [currentUser]);

  /*
  useEffect(() => {
    (async () => {
      // Start loading when the effect runs
      setIsLoading(true);

      // Check if currentUser exists and has a shopifyCustomerId
      if (currentUser && currentUser.shopifyCustomerId) {
        try {
          const response = await getUserOrdersAPI(
            currentUser.shopifyCustomerId
          );
          if (response) {
            if (response.ok) {
              const data = await response.json();
              setOrders(data.orders);
            } else {
              // Handle the case where the response is not ok (e.g., network error)
              console.error("Failed to fetch orders:", response.statusText);
            }
          }
        } catch (error) {
          // Handle any errors that occurred during the fetch
          console.error("Error fetching orders:", error);
        }
      }

      // Stop loading when currentUser is null or after attempting to fetch orders
      setIsLoading(false);
    })();
  }, [currentUser]);
 */

  return (
    <div
      className={
        "user-page-orders-container mx-auto mb-40 sm:min-w-[400px] max-w-[800px]"
      }
    >
      <h1 className="font-bold text-xl mb-10">Past Orders</h1>
      {currentUser && Object.keys(currentUser).length > 0 ? (
        isLoading ? (
          <p>Loading...</p>
        ) : orders.length !== 0 ? (
          <div className="user-page-orders">
            {orders.map((order) => {
              return <PastOrder order={order} user={currentUser} />;
            })}
          </div>
        ) : (
          <p className="user-page-name-badge-no-ticket">
            You don't have any orders yet. If you have purchased tickets, but
            orders are not showing, please refresh, else please contact{" "}
            <a href={`mailto:${emailAddress.tech}`}>{emailAddress.tech}</a>.
          </p>
        )
      ) : (
        <p>Please login first</p>
      )}
    </div>
  );
}

function PastOrder(props: any) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadReceipt = async () => {
    setIsDownloading(true);
    try {
      // Wrap the PDF creation in a Promise to ensure it's treated as an asynchronous operation
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          const lineItems: any[] = [];
          Object.keys(props.order.lineItems).forEach((variantId) => {
            const item = props.order.lineItems[variantId];
            const temp = {
              name: item.name[1] + "",
              quantity: item.quantity + "",
              unitPriceIncl: item.price + "",
              subtotal: item.price * item.quantity + "",
            };
            lineItems.push(temp);
          });

          createReceiptPDF(`Events House Receipt Order ${props.order.outTradeNo}`, {
            name: props.user.firstName + " " + props.user.lastName,
            billingAddress: props.order.billingAddress,
            receiptNumber: props.order.outTradeNo + "01",
            orderDate: props.order.paymentTime.slice(0, 10),
            orderNumber: props.order.outTradeNo,
            lineItems,
            conference: ["2024 International Conference on", "Intelligent Data Analysis and Sustainable Systems"],
            amount: props.order.amount,
            transAmount: props.order.transAmount,
            currency: props.order.currency,
            transCurrency: props.order.transCurrency,
            paymentChannel: props.order.paymentChannel,
          });
          resolve();
        }, 100); // Small delay to ensure state update is visible
      });
    } catch (error) {
      console.error("Error downloading receipt:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  /*

{[
      {
          "id": 6,
          "outTradeNo": "1728414831247-0ntj5dpp",
          "userId": 22,
          "lineItems": "{\"44968524710187\":{\"variantId\":\"44968524710187\",\"quantity\":1,\"name\":[\"2024 International Conference on Intelligent Data Analysis and Sustainable Systems (IDASS)\",\"test\"],\"price\":1}}",
          "billingAddress": "{\"line1\":\"fgrab\",\"line2\":\"\",\"city\":\"gra\",\"country\":\"brfea\",\"zip\":\"\"}",
      },
]

*/

  if (props.order.transactionStatus !== "paid") {
    return <></>;
  }

  return (
    <div className="mb-4 border p-4 rounded-sm">
      <div className="flex flex-col xs:flex-row justify-between gap-4 border-b border-slate-100 pb-4">
        <div className="flex flex-col justify-between text-sm items-start">
          <button
            className="bg-slate-200 px-3 py-1 mb-2 text-xs text-slate-700 font-medium rounded-sm w-40 h-6 mt-2 sm:w-auto sm:h-auto sm:mt-0"
            type="button"
            onClick={handleDownloadReceipt}
          >
            {isDownloading ? "Downloading..." : "Download Receipt"}
          </button>
          <p>Date: <span className="font-bold text-slate-800">{props.order.paymentTime.slice(0, 10)}</span></p>
          <p>Order No.: <span className="font-bold text-slate-800">{props.order.outTradeNo}</span></p>
        </div>
        <div className="text-left xs:text-right text-sm text-slate-700 font-bold">
          <p><span className="text-slate-600 font-normal">Total:</span> {getSymbolFromCurrency(props.order.currency)}{parseFloat(props.order.amount).toFixed(2)}</p>
          {props.order.transAmount ?
            <p><span className="text-slate-600 font-normal">Total paid:</span> {getSymbolFromCurrency(props.order.transCurrency)}{parseFloat(props.order.transAmount).toFixed(2)}</p>
            : <p></p>
          }
          <p><span className="text-slate-600 font-normal">Payment channel:</span> {props.order.paymentChannel}</p>
        </div>
      </div>
      <table className="w-full mt-4">
        <thead>
          <tr className="text-left">
            <th className="text-sm opacity-40">Product</th>
            <th className="text-sm opacity-40">Qty</th>
            <th className="text-sm opacity-40 text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(props.order.lineItems).map(([variantId, lineItem]) => (
            <tr key={variantId}>
              <td>{(lineItem as any).name[1]}</td>
              <td>{(lineItem as LineItem).quantity}</td>
              <td className="text-right">{getSymbolFromCurrency(props.order.currency)}{(lineItem as LineItem).price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
