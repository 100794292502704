import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Login } from "./Login";
import { Register } from "./Register";

interface AuthPageProps {
  page: string;
}

const AuthPage: React.FC<AuthPageProps> = ({ page }) => {
  return (
    <div className="register-login-container max-w-[500px] mx-auto mb-40">
      <div className="register-login-content">
        <div className="register-login-label">
          {page === "login" ? (
            <>
              <h3 className="font-bold text-xl">Welcome Back...</h3>
              <p>Please enter your email and password</p>
            </>
          ) : (
            <>
              <h3 className="font-bold text-xl">Welcome!</h3>
              <p>
                Please register to purchase tickets and attend the congress!
              </p>
              {page !== "login" && (
                <p>
                  <span className="opacity-60">Already have an account?</span>{" "}
                  <NavLink to="/login">
                    <b>Login</b>
                  </NavLink>
                </p>
              )}
            </>
          )}
        </div>
        {page === "login" ? <Login /> : <Register />}
        {page === "login" && (
          <p>
            Don't have an account?{" "}
            <NavLink to="/signup">
              <b>Sign up</b>
            </NavLink>
          </p>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
