import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/globalContext';


export default function UserPagePersonalDetails() {
    const { currentUserInfo } = useContext(GlobalContext);
    const [title, setTitle] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [organisation, setOrganisation] = useState();
    const [position, setPosition] = useState();
    const [email, setEmail] = useState();
    const [country, setCountry] = useState();


    useEffect(() => {
        if (currentUserInfo) {
            const { title, firstName, lastName, organisation, position, email, country } = currentUserInfo;
            setTitle(title);
            setFirstName(firstName);
            setLastName(lastName);
            setOrganisation(organisation);
            setPosition(position);
            setEmail(email);
            setCountry(country);
        }
    }, [currentUserInfo]);


    return (
        <div className={"user-page-personal-details-container"}>
            <div className="user-page-title">
                <h1>Personal Details</h1>
            </div>
            <div>
                <PersonalDetail title="Title" field="title" content={title} updateField={setTitle} />
                <PersonalDetail title="Name" field={["firstName", "lastName"]} content={[firstName, lastName]} updateField={null} />
                <PersonalDetail title="Email" field="email" content={email} updateField={null} />
                <PersonalDetail title="Organisation" field="organisation" content={organisation} updateField={setOrganisation} />
                <PersonalDetail title="Position" field="position" content={position} updateField={setPosition} />
                <PersonalDetail title="Country" field="country" content={country} updateField={setCountry} />
            </div>
        </div>
    );
}

function PersonalDetail(props: any) {
    const { currentUser, refreshCurrentUserInfo, currentUserInfo } = useContext(GlobalContext);
    const [hideUpdate, setHideUpdate] = useState(true);

    const handleFieldOnSave = async () => {
        if ((props.title === "Name" && (!props.content[0] || !props.content[1])) || (!props.content)) {
            window.alert("Field cannot be empty.");
            if (props.title === "Name") {
                props.updateField[0](currentUserInfo[props.field][0]);
                props.updateField[1](currentUserInfo[props.field][1]);
            } else {
                props.updateField(currentUserInfo[props.field]);
            }
            return;
        }

        // TODO: update fields in db here 
        // if (props.title === "Name") {
        //     updateUserInfoInFirestore(currentUser.uid, {
        //         [props.field[0]]: props.content[0].trim(),
        //         [props.field[1]]: props.content[1].trim(),
        //     });
        // } else {
        //     updateUserInfoInFirestore(currentUser.uid, {
        //         [props.field]: props.content.trim()
        //     });
        // }
        setHideUpdate(true);
        await refreshCurrentUserInfo(); // 必须要有这个，因为数据是从 currentUserInfo 拿的
    };

    return (
        <div className={`personal-detail personal-${props.title.toLowerCase()}`}>
            <h3>{props.title}</h3>
            {props.title !== "Email" && props.title !== "Name" &&
                <button className="btn-open-change"  >
                    {hideUpdate ?
                        <div onClick={() => { setHideUpdate(false); }}>
                            <span className="material-symbols-outlined">edit_square</span>
                            <span>Edit</span>
                        </div>
                        :
                        <div onClick={() => { setHideUpdate(true); }}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                            <span>Cancel</span>
                        </div>
                    }
                </button>
            }
            {hideUpdate && (
                props.title === "Name"
                    ?
                    <p>{`${props.content[0]} ${props.content[1]}`}</p>
                    :
                    <p>{props.content}</p>
            )}
            {props.title === "Email" && <p className="cannot-change">This cannot be changed.</p>}
            {props.title === "Name" && <p className="cannot-change">This cannot be changed.</p>}
            {props.title !== "Email" && props.title !== "Name" && !hideUpdate && (
                <div className="personal-detail-edit-change">
                    <label>
                        {props.title}
                        <input type="text" name="first-name" value={props.content} onChange={(e) => { props.updateField(e.target.value); }} />
                    </label>
                    <button className="btn-save-change" onClick={handleFieldOnSave}>Save</button>
                </div>
            )}
        </div>
    );
}
