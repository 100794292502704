import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import alipay from "../../assets/media/logos/payment/alipay-logo.png";
import wechatpay from "../../assets/media/logos/payment/wechat-pay-logo.png";
import styles from "./WeChatAlipayPage.module.css";
import { LineItem } from "../CartPage/CartPage";
import { LineItemType } from "../../types/lineItem";
import { NavLink, useLocation } from "react-router-dom";
import { API_ENDPOINT } from "../../api/index";
import { conferenceIDASS2024 } from "../../data/productData";
import { getFusionCodeAPI } from "../../api/user";
import getSymbolFromCurrency from "currency-symbol-map";
import { getExchangeRateAPI } from "../../api/payment";

export default function WeChatAlipayPage() {
  const [lineItems, setLineItems] = useState<Record<string, LineItemType>>({});
  const [name, setName] = useState("");
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    country: "",
    zip: "",
  });
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [error, setError] = useState("");
  const eventSourceRef = useRef<EventSource | null>(null);
  const location = useLocation();
  const [exchangeRate, setExchangeRate] = useState(1);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const rate = await getExchangeRateAPI();
        setExchangeRate(rate);
      } catch (err) {
        console.error("Error fetching exchange rate:", err);
        setError("Failed to fetch exchange rate");
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (qrCodeUrl) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 100); // 100ms delay
    }
  }, [qrCodeUrl]);

  // TO LOAD LINE ITEMS FROM URL //
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const items = searchParams.get("items");
    if (items) {
      const parsedItems = items.split(",").reduce((acc, item) => {
        const [variantId, quantity] = item.split(":") as [
          keyof typeof conferenceIDASS2024,
          string
        ];
        const productInfo = conferenceIDASS2024[variantId];
        if (productInfo) {
          acc[variantId] = {
            variantId,
            quantity: parseInt(quantity),
            name: [
              "2024 International Conference on Intelligent Data Analysis and Sustainable Systems (IDASS)",
              productInfo.name,
            ],
            price: productInfo.price,
          };
        }
        return acc;
      }, {} as Record<string, LineItemType>);
      setLineItems(parsedItems);
    }
  }, [location]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (qrCodeUrl && timeLeft === null) {
      setTimeLeft(5 * 60); // 5 minutes in seconds
    }
    if (timeLeft !== null && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime !== null ? prevTime - 1 : null));
      }, 1000);
    } else if (timeLeft === 0) {
      // Redirect to timeout page
      window.location.href = '/payment-timeout';
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [qrCodeUrl, timeLeft]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const setupSSE = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const eventSource = new EventSource(`${API_ENDPOINT}/payment-updates`, {
      withCredentials: true,
    });

    eventSource.onopen = (event) => {
    };

    eventSource.onmessage = (event) => {

      try {
        const data = JSON.parse(event.data);
        if (data.type === "connection_established") {
        } else if (data.type === "test_event") {
        } else if (data.type === "payment_success") {
          setPaymentStatus("success");
          setLineItems({});
          eventSource.close();
        }
      } catch (error) {
        console.error("Error parsing SSE message:", error);
      }
    };

    eventSource.onerror = (error) => {
      console.error("SSE error:", error);
      if (eventSource.readyState === EventSource.CLOSED) {
      } else if (eventSource.readyState === EventSource.CONNECTING) {
      }
    };

    eventSourceRef.current = eventSource;
    return eventSource;
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const handleGenerate = async () => {
    if (
      !name.trim() ||
      !address.line1.trim() ||
      !address.city.trim() ||
      !address.country.trim()
    ) {
      setError("Please fill in all required fields for the receipt.");
      return;
    }

    setIsLoading(true);
    try {
      const eventSource = setupSSE();

      // Wait for SSE connection confirmation
      await new Promise<void>((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error("SSE connection timeout"));
        }, 5000); // 5 seconds timeout

        const connectionListener = (event: MessageEvent) => {
          const data = JSON.parse(event.data);
          if (data.type === "connection_established") {
            clearTimeout(timeout);
            eventSource.removeEventListener("message", connectionListener);
            resolve();
          }
        };

        eventSource.addEventListener("message", connectionListener);

        eventSource.onerror = (error) => {
          clearTimeout(timeout);
          eventSource.removeEventListener("message", connectionListener);
          reject(error);
        };
      });


      const url = await getFusionCodeAPI({
        name,
        address,
        includeReceipt: true,
        lineItems,
        // transAmount: 0.1,
        transAmount: (totalCost * exchangeRate).toFixed(2),
        amount: totalCost.toFixed(2),
        exchangeRate,
        currency: "USD",
        trans_currency: "CNY",
      });
      setQrCodeUrl(url);
      setPaymentStatus("awaiting_payment");
    } catch (error) {
      console.error("Error fetching QR code:", error);
      setPaymentStatus("error");
    } finally {
      setIsLoading(false);
    }
  };

  const totalCost = Object.values(lineItems).reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  if (paymentStatus === "success") {
    return (
      <div className="flex flex-col mt-20 items-center justify-center h-full bg-white p-8">
        <h1 className="text-3xl font-bold mb-6 text-secondary-dark">
          Payment Successful
        </h1>
        <p className="text-lg text-center max-w-[600px] mb-2">
          Thank you for your payment. You will receive a confirmation email
          shortly. The receipt in your past orders can be used as proof for
          event registration. If you have any questions, please contact{" "}
          <a className="underline" href="mailto:contact@eventshouse.net">
            contact@eventshouse.net
          </a>
        </p>
        <NavLink
          to="/user/orders"
          className="bg-secondary-dark text-white px-6 py-2 rounded-md font-bold mt-10 hover:bg-secondary-dark/80"
        >
          My Past Orders
        </NavLink>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 mb-40 max-w-[900px]">
      <h1 className="text-2xl font-bold mb-4">Checkout</h1>
      <div className="flex gap-5">
        <img className="h-8" src={alipay} alt="alipay logo" />
        <img className="h-8" src={wechatpay} alt="wechatpay logo" />
      </div>
      <div className="flex mt-10 gap-20 items-start w-[400px]">
        <div className="min-w-[300px]">
          <div>
            {lineItems &&
              Object.keys(lineItems).length > 0 &&
              Object.keys(lineItems).map((key: string) => {
                return (
                  <LineItem key={key} item={lineItems[key]} edit={false} />
                );
              })}
            <p className="font-bold text-lg text-right">Total: {getSymbolFromCurrency("USD")}{totalCost.toFixed(2)}</p>
            {exchangeRate !== 1 && <p className="text-xs text-right opacity-40">Exchange rate: {exchangeRate}</p>}
            {exchangeRate !== 1 && <p className="text-sm text-right">Total: {getSymbolFromCurrency("CNY")}{(totalCost * exchangeRate).toFixed(2)}</p>}
          </div>
          {paymentStatus !== "awaiting_payment" &&
            <form
              className={`mb-4 mt-10 w-[400px] ${styles.billingForm}`}
              onFocus={() => {
                setError("");
              }}
              onChange={() => {
                setError("");
              }}
            >
              <p className="text-gray-700 font-bold mb-4">Billing Address</p>
              <label className="block text-gray-700">
                *Name
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="input input-sm w-full p-2 border border-gray-300 rounded"
                />
              </label>
              <label className="block text-gray-700">
                *Address Line 1
                <input
                  type="text"
                  value={address.line1}
                  onChange={(e) =>
                    setAddress({ ...address, line1: e.target.value })
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </label>
              <label className="block text-gray-700">
                Address Line 2
                <input
                  type="text"
                  value={address.line2}
                  onChange={(e) =>
                    setAddress({ ...address, line2: e.target.value })
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </label>
              <label className="block text-gray-700">
                *City
                <input
                  type="text"
                  value={address.city}
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </label>
              <label className="block text-gray-700">
                *Country
                <input
                  type="text"
                  value={address.country}
                  onChange={(e) =>
                    setAddress({ ...address, country: e.target.value })
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </label>
              <label className="block text-gray-700">
                Zip Code / Postal Code
                <input
                  type="text"
                  value={address.zip}
                  onChange={(e) =>
                    setAddress({ ...address, zip: e.target.value })
                  }
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </label>
              <button
                type="button"
                onClick={handleGenerate}
                className="font-bold mt-10 w-full bg-gradient-to-br from-blue-500 via-blue-400 to-green-500 text-white px-4 py-2 rounded hover:from-blue-600 hover:via-blue-500 hover:to-green-600 transition-colors duration-300"
              >
                {isLoading
                  ? "Creating Payment QR Code..."
                  : "Create Payment QR Code"}
              </button>
              {error && <p className="mt-2 text-red-700">{error}</p>}
            </form>
          }
        </div>
        {qrCodeUrl && (
          <div className="flex flex-col mt-2 items-center gap-6">
            <QRCode
              className="border p-2 w-[200px] h-[200px] opacity-80"
              value={qrCodeUrl}
            />
            <div className="text-center">
              <p className="text-nowrap">Please scan the QR code to pay</p>
              <p>by WeChat Pay or Alipay</p>
              <p>in WeChat or Alipay app</p>
              {timeLeft !== null && (
                <p className="mt-2 font-bold">Time remaining: {formatTime(timeLeft)}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
