import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext";
import { LineItemType } from "../../types/lineItem";
import { LineItem } from "../CartPage/CartPage";
import { NavLink } from "react-router-dom";
import paymentOther from "../../assets/media/logos/payment/other-payments-logo.png";
import paymentWechat from "../../assets/media/logos/payment/wechat-pay-logo.png";
import paymentAlipay from "../../assets/media/logos/payment/alipay-logo.png";

export default function CheckoutPage() {
  const { lineItems, setShowCart, setLineItems, currentUser, handleCheckout } =
    useContext(GlobalContext);

  const totalCost = Object.values(
    lineItems as Record<string, LineItemType>
  ).reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  if (!currentUser || Object.keys(currentUser).length === 0) {
    return <p className="text-center">Please Login or Register first.</p>;
  }

  if (!lineItems || Object.keys(lineItems).length === 0) {
    return <p className="text-center">No items in the cart.</p>;
  }

  return (
    <div className="container mx-auto p-4 max-w-[1000px]">
      <h1 className="text-2xl font-bold mb-4">Checkout</h1>
      <div className="p-3 px-4 border -mx-3 rounded-sm">
        <table className="min-w-full bg-white ">
          <thead className="">
            <tr>
              <th className="py-2 text-left">Item</th>
            </tr>
          </thead>
          <tbody>
            {(Object.values(lineItems) as LineItemType[]).map((item, index) => (
              <LineItem key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 text-right">
        <p className="text-xl font-bold">Total: ${totalCost.toFixed(2)}</p>
      </div>
      {/* <p>
        Please make sure to use the same email on the checkout page as the one
        you registered with
      </p> */}
      <div className="mt-4 flex justify-end gap-2 flex-col sm:flex-row">
        <p className="pt-2 text-right mr-2  text-gray-400">Checkout With</p>
        <button
          onClick={() => handleCheckout("wechat-alipay")}
          disabled={!(lineItems && Object.keys(lineItems).length > 0)}
          className="flex flex-col gap-2 items-stretch"
        >
          <span className="bg-gradient-to-br from-blue-500 via-blue-400 to-green-500 text-white px-4 py-2 rounded hover:bg-gradient-to-br hover:from-blue-600 hover:via-blue-500 hover:to-green-600">
            WeChat Pay and Alipay
          </span>
          <div className="flex gap-2 items-center self-center">
            <img className="w-20" src={paymentWechat} alt="wechat pay logo" />
            <img className="w-20" src={paymentAlipay} alt="alipay logo" />
          </div>
        </button>
        <button
          onClick={() => handleCheckout("other-payments")}
          disabled={!(lineItems && Object.keys(lineItems).length > 0)}
          className="flex flex-col gap-2 items-stretch"
        >
          {/* <span className='bg-gradient-to-br from-gray-700 to-blue-900 text-white px-4 py-2 rounded hover:bg-gradient-to-br hover:from-gray-800 hover:to-blue-950 flex-grow'>Checkout</span> */}
          <span className="bg-gradient-to-br from-gray-700 to-blue-900 text-white px-4 py-2 rounded hover:bg-gradient-to-br hover:from-gray-800 hover:to-blue-950 flex-grow">
            Other Payments
          </span>
          <img
            className="w-40 flex-grow self-center"
            src={paymentOther}
            alt="payment method logos"
          />
        </button>
      </div>
    </div>
  );
}
