import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FormInput from "../../components/Form/FormInput/FormInput";
import FormCheckbox from "../../components/Form/FormRadio/FormCheckbox";
import { GlobalContext } from "../../context/globalContext";
import { loginUserAPI } from "../../api/auth";
import Spinner from "../../components/Spinner/Spinner";

const emailIcon = require("../../assets/media/icon/email1.png");
const lockIcon = require("../../assets/media/icon/lock1.png");

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginRemember, setLoginRemember] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const { setCurrentUser } = useContext(GlobalContext);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  let navigate = useNavigate();

  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Missing fields.");
      return;
    }
    if (!email.includes("@")) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      setIsLoggingIn(true);
      const response = await loginUserAPI({ email, password, loginRemember });
      if (response && response.status === 200) {
        setMessage("Login successful!");
        const data = await response.json();
        const { id, shopifyCustomerId, email, firstName, lastName, title } =
          data.user;
        setCurrentUser({
          id,
          shopifyCustomerId,
          email,
          firstName,
          lastName,
          title,
        });
        setIsLoggingIn(false);
        navigate("/");
      } else {
        if (response) {
          const data = await response.json();
          setErrorMessage(data.message);
        } else {
          setErrorMessage("Network error. Please try again later.");
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage(
        "An error occurred during login. Please try again later."
      );
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div className="login-container mt-10">
      <form
        id="form-login"
        action="/login"
        method="POST"
        onSubmit={loginUser}
        onChange={() => {
          setErrorMessage("");
          setMessage("");
        }}
      >
        <FormInput
          input={{
            class: "static bg-white",
            label: "Email",
            type: "txt",
            value: email,
            img: emailIcon,
            update: (value: string) => {
              setEmail(value);
            },
          }}
        />
        <FormInput
          input={{
            class: "static bg-white",
            label: "Password",
            type: "password",
            value: password,
            img: lockIcon,
            update: (value: string) => {
              setPassword(value);
            },
          }}
        />
        <div className="login-rmbr-forgot">
          <FormCheckbox
            input={{
              class: "bg-white",
              label: "Remember me",
              type: "checkbox",
              value: loginRemember,
              update: (value: boolean) => {
                setLoginRemember(value);
              },
            }}
          />
          <NavLink to="/reset-password">Forgotten your password?</NavLink>
        </div>
        <div className="flex flex-col items-center mt-6">
          <button
            id="login-button"
            type="submit"
            className="btn w-full btn-sm bg-slate-800 text-white"
          >
            {isLoggingIn ? <Spinner /> : "Login"}
          </button>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          {message && <p>{message}</p>}
        </div>
      </form>
    </div>
  );
};
