import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { GlobalContainer } from "./context/globalContext";
import ReactGA from "react-ga4";
import "tailwindcss/tailwind.css";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
  useMutation,
  ApolloProvider,
} from "@apollo/client";

const httpLink = createHttpLink({
  uri: "https://41f212.myshopify.com/api/2024-04/graphql.json",
  // uri: 'https://41f212.myshopify.com/api/2023-01/graphql.json',
  headers: {
    "X-Shopify-Storefront-Access-Token": "95dc404989fe8fe2246747996b6599cf",
  },
});

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

// ReactGA.initialize("G-PN1DSW3WKE");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

const RootComponent = () => {
  const [analytics, setAnalytics] = useState(
    localStorage.getItem("cookieAnalytics")
      ? +localStorage.getItem("cookieAnalytics")!
      : 0
  );
  useEffect(() => {
    if (analytics) {
      reportWebVitals(SendAnalytics);
    }
  }, [analytics]);

  return (
    <ApolloProvider client={apolloClient}>
      <GlobalContainer setAnalytics={setAnalytics} />
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<RootComponent />);
