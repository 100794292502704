import EventPage from "./EventPage";
import map from "../../assets/media/background/map.png";
import img from "../../assets/media/background/conference2 Large.jpeg";

export default function EventSWC2023() {
    //   const [pricingInfo, setPricingInfo] = useState([]);

    //   useEffect(() => {
    //     (async () => {
    //       const info = await getProductsInfoAPI();
    //       if (info) {
    //         const data = await info.json();
    //         setPricingInfo(data);
    //       }
    //     })();
    //   }, []);

    return (
        <EventPage
            eventName="IEEE SWC 2023"
            tags={["Technology", "Innovation", "Networking"]}
            img={img}
            basicInfo={{
                eventDescriptionShort: [
                    "A gathering of tech enthusiasts",
                    " and professionals.",
                ],
                eventDescriptionLong: [
                    "Tech Conference 2023 is the premier event for technology enthusiasts and industry professionals. This year's conference promises to be an unparalleled gathering of minds eager to delve into the latest technological advancements and discuss the future landscape of our digital world.",
                    "Attendees will have the opportunity to engage with visionary leaders, gain insights from expert-led sessions, and discover cutting-edge innovations that are shaping our tomorrow. Whether you're looking to stay ahead of the curve in your professional field or simply passionate about technology, this event is the perfect platform to connect, learn, and grow.",
                ],
                date: "2023-09-15",
                time: "10:00 AM",
                location: "Convention Center, San Francisco, CA",
                status: "Open for Registration",
            }}
            keynoteInfo={[
                {
                    name: "John Doe",
                    desc: "John Doe is a renowned industry leader with over 20 years of experience in the tech field. He has been instrumental in the development of several groundbreaking technologies.",
                    keynoteTitle: "The Future of Technology: A Look Ahead",
                },
                {
                    name: "Jane Smith",
                    desc: "Jane Smith is a visionary in the field of artificial intelligence. Her research and insights have shaped the way we understand and utilize AI today.",
                    keynoteTitle:
                        "Artificial Intelligence: Transforming the World as We Know It",
                },
            ]}
            scheduleInfo={[
                {
                    date: "2023-09-15",
                    schedule: [
                        {
                            time: "10:00 AM",
                            location: "Hall A",
                            title: "Opening Keynote",
                            desc: "An opening keynote by industry leader John Doe on the impact of technology.",
                        },
                        {
                            time: "12:00 PM",
                            location: "Room 101",
                            title: "Workshop: IoT Security",
                            desc: "Hands-on workshop on securing Internet of Things devices.",
                        },
                    ],
                },
                {
                    date: "2023-09-16",
                    schedule: [
                        {
                            time: "09:00 AM",
                            location: "Hall B",
                            title: "Panel Discussion: The Future of AI",
                            desc: "A panel discussion on the future of artificial intelligence and its implications.",
                        },
                        {
                            time: "11:00 AM",
                            location: "Room 102",
                            title: "Workshop: Data Science for Everyone",
                            desc: "An interactive workshop on the basics of data science for attendees of all skill levels.",
                        },
                    ],
                },
                {
                    date: "2023-09-17",
                    schedule: [
                        {
                            time: "10:00 AM",
                            location: "Hall C",
                            title: "Product Launch: NextGen Smartwatch",
                            desc: "Launch event for the latest smartwatch technology in wearable devices.",
                        },
                        {
                            time: "01:00 PM",
                            location: "Room 103",
                            title: "Seminar: Cybersecurity Trends",
                            desc: "A seminar on the latest trends and best practices in cybersecurity.",
                        },
                    ],
                },
            ]}
            pricingInfo={[
                {
                    name: "First Paper - IEEE Member (standard)",
                    price: 600,
                    variantId: "44674285797675",
                },
                {
                    name: "First Paper - Non-member (standard)",
                    price: 700,
                    variantId: "44674289533227",
                },
                {
                    name: "First Paper - Student IEEE Member (standard)",
                    price: 500,
                    variantId: "44674290123051",
                },
                {
                    name: "First Paper - Student Non-member (standard)",
                    price: 600,
                    variantId: "44674290483499",
                },
                {
                    name: "First Paper - Life Member (standard)",
                    price: 500,
                    variantId: "44674287305003",
                },
                {
                    name: "Each Additional Paper - IEEE Member (standard)",
                    price: 550,
                    variantId: "44810522525995",
                },
                {
                    name: "Each Additional Paper - Non-member (standard)",
                    price: 650,
                    variantId: "44810540253483",
                },
                {
                    name: "Each Additional Paper - Student IEEE Member (standard)",
                    price: 450,
                    variantId: "44810541957419",
                },
                {
                    name: "Each Additional Paper - Student Non-member (standard)",
                    price: 550,
                    variantId: "44810544152875",
                },
                {
                    name: "Each Additional Paper - Life Member (standard)",
                    price: 450,
                    variantId: "44810547331371",
                },
                {
                    name: "Listener - IEEE Member (standard)",
                    price: 450,
                    variantId: "44631241490731",
                },
                {
                    name: "Listener - Non-member (standard)",
                    price: 550,
                    variantId: "44634806714667",
                },
                {
                    name: "Listener - Student IEEE Member (standard)",
                    price: 400,
                    variantId: "44634806911275",
                },
                {
                    name: "Listener - Student Non-member (standard)",
                    price: 450,
                    variantId: "44634807501099",
                },
                {
                    name: "Listener - Life Member (standard)",
                    price: 400,
                    variantId: "44634807697707",
                },
                {
                    name: "Listener - IEEE Member (onsite)",
                    price: 730,
                    variantId: "46629995446571",
                },
                {
                    name: "Listener - Non-member (onsite)",
                    price: 830,
                    variantId: "46630054756651",
                },
                {
                    name: "Listener - Student IEEE Member (onsite)",
                    price: 630,
                    variantId: "46630071763243",
                },
                {
                    name: "Listener - Student Non-member (onsite)",
                    price: 730,
                    variantId: "46630088966443",
                },
                {
                    name: "Listener - Life Member (onsite)",
                    price: 630,
                    variantId: "46630022414635",
                },
                {
                    name: "Extra Page (max 2 pages)",
                    price: 50,
                    variantId: "44674311684395",
                },
                {
                    name: "Banquet and Award Ceremony Ticket (standard)",
                    price: 80,
                    variantId: "44674312503595",
                },
                {
                    name: "Banquet and Award Ceremony Ticket (onsite)",
                    price: 100,
                    variantId: "46629977325867",
                },
            ]}
            contactInfo={{
                email: "contact@techconference.com",
                phone: "123-456-7890",
                office: "123 Tech Lane, San Francisco, CA",
                mapImg: map,
            }}
        />
    );
}