import React from "react";

export default function CloseIcon({
  width,
  color,
}: {
  width: number;
  color: string;
}) {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1625"
      width={width}
      height={width}
      fill={color}
    >
      <path
        d="M928 164.9L861.1 98 513 446.1 164.9 98 98 164.9 446.1 513 98 861.1l66.9 66.9L513 579.9 861.1 928l66.9-66.9L579.9 513z"
        p-id="1626"
      ></path>
    </svg>
  );
}
