import { API_ENDPOINT } from ".";
export const getExchangeRateAPI = async () => {
    try {
        const response = await fetch(`${API_ENDPOINT}/exchange-rate`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data) {
            return data.usdToCny;
        } else {
            throw new Error("No data returned from exchange rate API");
        }
    } catch (err) {
        console.error("Error fetching user orders:", err);
        throw err; // Re-throw the error so it can be handled by the caller
    }
};