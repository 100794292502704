import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
// import AboutUsPage from '../pages/AboutUsPage/AboutUsPage';
// import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';
// import { PoliciesCookies, PoliciesPrivacy, PoliciesTerms } from '../pages/Policies/Policies';
import HomePage from "../pages/HomePage/HomePage";
import EventSWC2023 from "../pages/EventPage/Event01SWC2023";
import EventIDASS from "../pages/EventPage/EventI02DASS";
import AuthPage from "../pages/AuthPage/AuthPage";
import UserAuth from "../pages/AuthPage/UserAuth";
import ForgotPassword from "../pages/AuthPage/ForgotPassword";
import HostFormPage from "../pages/HostFormPage/HostFormPage";
import UserPage from "../pages/UserPage/UserPage";
import UserPageOrders from "../pages/UserPage/UserPageOrders";
import UserPagePreferences from "../pages/UserPage/UserPagePreferences";
import UserPagePersonalDetails from "../pages/UserPage/UserPagePersonalDetails";
import CartPage from "../pages/CartPage/CartPage";
import { GlobalContext } from "../context/globalContext";
import AlertWindow from "../components/AlertWindow/AlertWindow";
import CheckoutPage from "../pages/CheckoutPage/CheckoutPage";
import WeChatAlipayPage from "../pages/CheckoutPage/WeChatAlipayPage";
import PaymentTimeoutPage from "../pages/OtherPage/PaymentTimeoutPage";
import Cookie from "../layouts/Cookie/Cookie";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

function Content() {
  const { customAlert } = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Header />
      <main className="pt-24 px-6 min-h-screen bg-white">
        <Routes>
          {/* <Route path="/about-us" element={<AboutUsPage />} /> */}
          {/* <Route path="/contact-us" element={<ContactUsPage />} /> */}
          <Route
            path="/login"
            element={
              <>
                <Helmet>
                  <title>Login | Events House</title>
                </Helmet>
                <AuthPage page="login" />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Helmet>
                  <title>Signup | Events House</title>
                </Helmet>
                <AuthPage page="register" />
              </>
            }
          />
          {/* <Route
            path="/user/auth"
            element={
              <>
                <Helmet>
                  <title>User | Events House</title>
                </Helmet>
                <UserAuth />
              </>
            }
          /> */}
          <Route
            path="/user/dashboard"
            element={
              <>
                <Helmet>
                  <title>Dashboard | Events House</title>
                </Helmet>
                <UserPage />
              </>
            }
          />
          <Route
            path="/user/orders"
            element={
              <>
                <Helmet>
                  <title>Orders | Events House</title>
                </Helmet>
                <UserPageOrders />
              </>
            }
          />
          <Route
            path="/user/preferences"
            element={
              <>
                <Helmet>
                  <title>Perferences | Events House</title>
                </Helmet>
                <UserPagePreferences />
              </>
            }
          />
          <Route
            path="/user/account"
            element={
              <>
                <Helmet>
                  <title>Account | Events House</title>
                </Helmet>
                <UserPagePersonalDetails />
              </>
            }
          />
          <Route
            path="/reset-password"
            element={
              <>
                <Helmet>
                  <title>Reset Password | Events House</title>
                </Helmet>
                <ForgotPassword />
              </>
            }
          />
          <Route
            path="/form"
            element={
              <>
                <Helmet>
                  <title>Submit an Event | Events House</title>
                </Helmet>
                <HostFormPage />
              </>
            }
          />
          <Route
            path="/user"
            element={
              <>
                <Helmet>
                  <title>User | Events House</title>
                </Helmet>
                <UserPage />
              </>
            }
          />

          <Route path="/payment-timeout" element={
            <>
              <Helmet>
                <title>Timeout</title>
              </Helmet>
              <PaymentTimeoutPage />
            </>
          } />
          <Route path="/ieee-swc-2023" element={
            <>
              <Helmet>
                <title>IEEE SWC 2023 | Events House</title>
              </Helmet>
              <EventSWC2023 />
            </>
          } />
          <Route path="/idass-2024" element={
            <>
              <Helmet>
                <title>IDASS 2024 | Events House</title>
              </Helmet>
              <EventIDASS />
            </>
          } />
          <Route path="/checkout/wechat-alipay" element={
            <>
              <Helmet>
                <title>Checkout | Events House</title>
              </Helmet>
              <WeChatAlipayPage />
            </>
          } />
          <Route path="/checkout" element={
            <>
              <Helmet>
                <title>Checkout | Events House</title>
              </Helmet>
              <CheckoutPage />
            </>
          } />





          <Route path="/" element={<Navigate to="/idass-2024" />} />
          {/* <Route path="/" element={<HomePage />} /> */}
          {/* <Route path="/policies/terms-and-conditions" element={<PoliciesTerms />} />
                    <Route path="/policies/privacy-policy" element={<PoliciesPrivacy />} />
                    <Route path="/policies/cookies-policy" element={<PoliciesCookies />} /> */}
        </Routes>
      </main>
      <Cookie />
      <Footer />
      <CartPage />
      {customAlert && <AlertWindow>{customAlert}</AlertWindow>}
    </>
  );
}
