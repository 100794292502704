import { API_ENDPOINT } from ".";

export const sendEmailAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/send-email`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    return response.status;
  } catch (err) {
    console.error(err);
  }
};
export const sendEmailHostFormAPI = async (formData: FormData) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/send-email-form`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    return response.status;
  } catch (err) {
    console.error(err);
  }
};
