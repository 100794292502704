import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/globalContext";
import styles from "./CartPage.module.css";
import CloseIcon from "../../components/Icon/CloseIcon";
import { NavLink } from "react-router-dom";

interface LineItems {
  [key: string]: {
    name: string[];
    price: number;
    variantId: number;
    quantity: number;
  };
}

export default function CartPage() {
  const { lineItems, setLineItems, showCart, setShowCart, handleCheckout } =
    useContext(GlobalContext);

  const [totalPrice, setTotalPrice] = useState(0);
  // const lineItemsTemp = [
  //   { name: "bTegreast1", price: 91, variantId: 5738041, quantity: 2 },
  //   { name: "graTegrdaest2", price: 911, variantId: 1541, quantity: 1 },
  //   { name: "graTest3", price: 911, variantId: 547801, quantity: 1 },
  //   { name: "Tereast4", price: 171, variantId: 417841, quantity: 4 },
  //   { name: "tyrTebfeast5", price: 821, variantId: 89238041, quantity: 2 },
  // ];

  // function handleCheckout() {
  //   // let finalURL = "https://www.ieee-swc.org/cart/";
  //   let finalURL = "https://41f212.myshopify.com/cart/";
  //   Object.entries(lineItems).forEach(([key, item]: [string, any], index) => {
  //     finalURL += (index > 0 ? "," : "") + item.variantId + ":" + item.quantity;
  //   });
  //   // finalURL += "note=Your+Note+Here";
  //   window.location.href = finalURL;
  // }

  useEffect(() => {
    const calculateTotalPrice = () => {
      if (!lineItems || Object.keys(lineItems).length === 0) {
        return 0;
      }
      return Object.values(lineItems).reduce((total: number, item: any) => {
        return total + item.price * item.quantity;
      }, 0);
    };

    setTotalPrice(calculateTotalPrice());
  }, [lineItems]);

  return (
    <div
      id="cart"
      className={`${showCart ? "translate-x-0" : "translate-x-[100%]"
        } transition-all z-[99999999] fixed h-screen w-screen sm:w-[400px] right-0 top-0 bg-white p-4 shadow-md border-l`}
    >
      <div className="flex justify-between items-center border-b pb-4 border-slate-100">
        <h2 className="font-bold text-xl ">My Cart</h2>
        <div
          className="hover:cursor-pointer font-mono text-xl hover:bg-slate-100 p-2 rounded-full"
          onClick={() => {
            setShowCart(false);
          }}
        >
          <CloseIcon width={15} color="rgb(51 65 85)" />
        </div>
      </div>

      <div
        className={`${styles.cart} overflow-y-scroll pb-10 pt-4`}
        onScroll={(event) => event.stopPropagation()}
      >
        {lineItems && Object.keys(lineItems).length > 0 ? (
          Object.keys(lineItems).map((key: string) => {
            return (
              <LineItem
                key={key}
                item={lineItems[key]}
              />
            );
          })
        ) : (
          <p className="text-slate-400 text-center my-auto mt-20">
            Your Cart is Empty ...
          </p>
        )}
      </div>
      <NavLink
        to="/checkout"
        className={`mt-auto w-full py-4 absolute bottom-0 left-[50%] translate-x-[-50%] bg-white border-t border-slate-100 ${!(lineItems && Object.keys(lineItems).length > 0) &&
          "opacity-50 pointer-events-none"
          }`}
        onClick={(() => { setShowCart(false); })}
      >
        <p className="bg-slate-700 p-2 mx-4 text-white rounded-sm font-bold gap-4 flex justify-between ">
          <span className="text-nowrap font-normal">Total ${totalPrice}</span>
          <span>Checkout</span>
        </p>
      </NavLink>
    </div>
  );
}

export function LineItem({ item, edit = true }: { item: any; edit?: boolean; }) {
  const { setLineItems } = useContext(GlobalContext);
  function removeAProduct() {
    setLineItems((prevItems: any) => {
      const updatedItems = { ...prevItems };
      if (updatedItems[item.variantId].quantity > 1) {
        updatedItems[item.variantId].quantity -= 1;
      } else {
        delete updatedItems[item.variantId];
      }
      return updatedItems;
    });
  }
  function addAProduct() {
    setLineItems((prevItems: any) => {
      const updatedItems = { ...prevItems };
      if (updatedItems[item.variantId]) {
        updatedItems[item.variantId].quantity += 1;
      }
      return updatedItems;
    });
  }
  return (
    <div className="flex gap-3 mb-5 justify-between items-end">
      <div>
        <p className="text-xs text-wrap text-slate-400">{item.name[0]}</p>
        <p>
          <span className={`font-medium text-wrap ${!edit ? "text-base" : "text-lg"}`}>{item.name[1]}</span>
        </p>
      </div>
      <div className="flex gap-5 items-center">
        <span className="opacity-50 text-xs text-nowrap">x {item.quantity}</span>
        <p className="font-bold">${(item.price * item.quantity).toFixed(2)}</p>
        {edit &&
          <div className="flex gap-1">
            <button
              type="button"
              onClick={removeAProduct}
              className="bg-slate-100 hover:cursor-pointer px-2 rounded-md font-medium text-slate-600 font-mono"
            >
              -
            </button>
            <button
              type="button"
              onClick={addAProduct}
              className="bg-slate-100 hover:cursor-pointer px-2 rounded-md font-medium text-slate-600 font-mono"
            >
              +
            </button>
          </div>
        }
      </div>
    </div>
  );
}
