import { gql } from '@apollo/client';

export const GET_CUSTOMER_QUERY = gql`
{
  customer(email: "customer_email@example.com") {
    id
    email
    firstName
    lastName
  }
}

`;

export const CREATE_USER_MUTATION = gql`
mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customerUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}

`;

export const CREATE_ACCESS_TOKEN_MUTATION = gql`
mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;

export const SEND_ACTIVATION_URL = gql`
mutation sendInvite($customerId: ID!) {
  customerSendInvite(customerId: $customerId) {
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;