import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../context/globalContext";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
// import { getProductsInfoAPI } from "../../api/product";
// import AlertWindow from "../../components/AlertWindow/AlertWindow";
import { NavLink, useNavigate } from "react-router-dom";

interface EventContent {
  eventName: string;
  tags: string[];
  img: any;
  basicInfo: {
    eventDescriptionShort: string[];
    eventDescriptionLong: string[];
    date: string;
    time?: string;
    location: string;
    status: string;
    link?: string;
  };
  keynoteInfo?: {
    name: string;
    desc: string;
    keynoteTitle: string;
  }[];
  scheduleInfo?: {
    date: string;
    schedule: {
      time: string;
      location: string;
      title: string;
      desc: string;
    }[];
  }[];
  pricingInfo: {
    name: string;
    variantId: string;
    price: number;
  }[];
  contactInfo: {
    email: string;
    phone?: string;
    office?: string;
    mapImg: any;
  };
}

interface EventScheduleProps {
  scheduleInfo?: {
    date: string;
    schedule: {
      time: string;
      location: string;
      title: string;
      desc: string;
    }[];
  }[];
}

interface KeynoteProps {
  keynoteInfo?: {
    name: string;
    desc: string;
    keynoteTitle: string;
  }[];
}

interface ContactProps {
  contactInfo: {
    email: string;
    phone?: string;
    office?: string;
    mapImg: any;
  };
}

interface BasicProps {
  basicInfo: {
    eventDescriptionShort: string[];
    eventDescriptionLong: string[];
    date: string;
    time?: string;
    location: string;
    status: string;
    link?: string;
  };
}

interface PricingProps {
  pricingInfo: {
    name: string;
    price: number;
    variantId: string;
  }[];
  eventName: string;
}

const EventPage: React.FC<EventContent> = ({
  eventName,
  tags,
  img,
  basicInfo,
  keynoteInfo,
  scheduleInfo,
  pricingInfo,
  contactInfo,
}) => {
  const basicInfoRef = useRef(null);
  const contactInfoRef = useRef(null);
  const pricingRef = useRef(null);
  const keynoteRef = useRef(null);
  const scheduleRef = useRef(null);

  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px" }
    );

    // Observing each section
    const sections = [
      basicInfoRef,
      contactInfoRef,
      pricingRef,
      keynoteRef,
      scheduleRef,
    ];
    sections.forEach((section) => {
      if (section.current) {
        observer.observe(section.current);
      }
    });

    // Cleanup observer on unmount
    return () => {
      sections.forEach((section) => {
        if (section.current) {
          observer.unobserve(section.current);
        }
      });
    };
  }, []);
  const scrollToRef = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-white max-w-[1200px] mx-auto px-2 sm:px-10 text-slate-800 flex flex-col items-center mb-40">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center mb-16">
          <h1 className="font-extrabold text-4xl text-center">{eventName}</h1>
          <p className="mt-6 mb-3 text-center">{basicInfo.eventDescriptionShort}</p>
          <div className="flex gap-2 flex-col sm:flex-row items-center">
            {tags.map((i) => {
              return (
                <span key={"tag-" + i} className="bg-slate-100 text-sm font-bold py-1 px-3 rounded-sm">
                  {i}
                </span>
              );
            })}
          </div>
        </div>

        <img className="" src={img} alt="" />
      </div>
      <Nav
        activeSection={activeSection}
        onBasicInfoClick={() => scrollToRef(basicInfoRef)}
        onContactInfoClick={() => scrollToRef(contactInfoRef)}
        onPricingClick={() => scrollToRef(pricingRef)}
        onKeynoteClick={() => scrollToRef(keynoteRef)}
        onScheduleClick={() => scrollToRef(scheduleRef)}
      />
      <div className="flex flex-col gap-20">
        <div id="basicInfo" ref={basicInfoRef}>
          <Basic basicInfo={basicInfo} />
        </div>
        {/* <div id="scheduleInfo" ref={scheduleRef}>
          <EventSchedule scheduleInfo={scheduleInfo} />
        </div>
        <div id="keynoteInfo" ref={keynoteRef}>
          <Keynote keynoteInfo={keynoteInfo} />
        </div> */}
        <div id="pricingInfo" ref={pricingRef}>
          <Pricing pricingInfo={pricingInfo} eventName={eventName} />
        </div>
        {/* <div id="contactInfo" ref={contactInfoRef}>
          <Contact contactInfo={contactInfo} />
        </div> */}
      </div>
    </div>
  );
};

export default EventPage;

function Nav({
  activeSection,
  onBasicInfoClick,
  onContactInfoClick,
  onPricingClick,
  onKeynoteClick,
  onScheduleClick,
}: any) {
  return (
    <div className="z-[9999999] inline-block sticky top-[70px] mt-10 mb-2 rounded-full overflow-hidden shadow-sm border-[0.5px] border-slate-100">
      <nav
        className="flex text-nowrap gap-10 p-4 rounded-full "
        style={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <button
          onClick={onBasicInfoClick}
          className={`hover:underline ${activeSection === "basicInfo" ? "font-bold" : ""
            }`}
        >
          Basic Info
        </button>
        {/* <button
          onClick={onScheduleClick}
          className={`hover:underline ${activeSection === "scheduleInfo" ? "font-bold" : ""
            }`}
        >
          Event Schedule
        </button>
        <button
          onClick={onKeynoteClick}
          className={`hover:underline ${activeSection === "keynoteInfo" ? "font-bold" : ""
            }`}
        >
          Keynote Speakers
        </button> */}
        <button
          onClick={onPricingClick}
          className={`hover:underline ${activeSection === "pricingInfo" ? "font-bold" : ""
            }`}
        >
          Pricing
        </button>
        {/* <button
          onClick={onContactInfoClick}
          className={`hover:underline ${activeSection === "contactInfo" ? "font-bold" : ""
            }`}
        >
          Contact Info
        </button> */}
      </nav>
    </div>
  );
}

function Keynote({ keynoteInfo }: KeynoteProps) {
  return (
    <div>
      <SectionHeader
        subtitle="Highlight"
        header="Keynote Speakers"
        description="Learn about our keynote speakers and their topics of discussion."
      />
      {keynoteInfo && (
        <div className="flex flex-col gap-4 border-b border-slate-700">
          {keynoteInfo.map((info, index) => (
            <div key={index} className="py-4 border-t border-slate-700">
              <div className="flex gap-3 items-center mb-3">
                <h3 className="font-bold text-xl">{info.name}</h3>
                <h4 className="italic">{info.keynoteTitle}</h4>
              </div>
              <p>{info.desc}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function EventSchedule({ scheduleInfo }: EventScheduleProps) {
  const [selectedDate, setSelectedDate] = useState(0);

  return (
    <div>
      <SectionHeader
        subtitle="Discover"
        header="Event Schedule"
        description="Check out the event schedule to plan your day."
      />
      {scheduleInfo && (
        <div>
          <ul className={`flex gap-4 mb-6`}>
            {scheduleInfo.map((info, index) => (
              <li key={index}>
                <button
                  className={`py-2 px-2 border  ${selectedDate === index
                    ? "border-slate-700"
                    : "border-transparent"
                    }`}
                  type="button"
                  onClick={() => setSelectedDate(index)}
                >
                  {info.date}
                </button>
              </li>
            ))}
          </ul>
          {scheduleInfo[selectedDate] && (
            <div className="flex flex-col border-b border-slate-700">
              {scheduleInfo[selectedDate].schedule.map((item, index) => (
                <div
                  className="border-t border-slate-700 py-8 flex justify-between gap-3 items-center"
                  key={index}
                >
                  <div className="flex gap-10 items-center">
                    <h3>{item.time}</h3>
                    <div>
                      <h4 className="font-bold text-lg">{item.title}</h4>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                  <p className="text-nowrap">{item.location}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function Contact({ contactInfo }: ContactProps) {
  return (
    <div>
      <SectionHeader
        subtitle="Connect"
        header="Get in Touch"
        description="Have any questions or need assistance? Contact our support team."
      />
      <div className="flex justify-between mt-20 gap-10">
        <div className="flex flex-col gap-10 h-full justify-between">
          <div>
            <span className="material-symbols-outlined">mail</span>
            <p className="font-bold text-lg">Email</p>
            <p>{contactInfo.email}</p>
          </div>
          {contactInfo.phone && (
            <div>
              <span className="material-symbols-outlined">phone_in_talk</span>
              <p className="font-bold text-lg">Phone</p>
              <p>{contactInfo.phone}</p>
            </div>
          )}
          {contactInfo.office && (
            <div>
              <span className="material-symbols-outlined">pin_drop</span>
              <p className="font-bold text-lg">Office</p>
              <p>{contactInfo.office}</p>
            </div>
          )}
        </div>
        <img
          className="max-w-[60%] object-cover"
          src={contactInfo.mapImg}
          alt=""
        />
      </div>
    </div>
  );
}

function Basic({ basicInfo }: BasicProps) {
  return (
    <div className="flex flex-col sm:flex-row gap-10 justify-between pt-10 sm:pt-40">
      <p className="font-extrabold text-2xl">
        {basicInfo.eventDescriptionShort.map((i) => {
          return <span key={"event-desc-" + i} className="block">{i}</span>;
        })}
      </p>
      <div className="sm:max-w-[50%]">
        <div>
          {basicInfo.eventDescriptionLong.map((i) => {
            return <p key={"event-desc-long-" + i} className="mb-6">{i}</p>;
          })}
        </div>
        <ul className="mt-14">
          <li className="flex justify-between border-b border-t border-slate-700 py-2">
            <span className="font-bold">Date</span>
            <span className="font-medium">{basicInfo.date}</span>
          </li>
          {basicInfo.time &&
            <li className="flex justify-between border-b border-slate-700 py-2">
              <span className="font-bold">Time</span>
              <span className="font-medium">{basicInfo.time}</span>
            </li>
          }
          <li className="flex justify-between border-b border-slate-700 py-2">
            <span className="font-bold">Location</span>
            <span className="font-medium text-right">{basicInfo.location}</span>
          </li>
          <li className="flex justify-between border-b border-slate-700 py-2">
            <span className="font-bold">Tickets</span>
            <span className="font-medium">{basicInfo.status}</span>
          </li>
          {basicInfo.link &&
            <li className="flex justify-between border-b border-slate-700 py-2">
              <span className="font-bold">Website</span>
              <span className="font-medium"><a href={basicInfo.link} target="_blank">{basicInfo.link}</a></span>
            </li>
          }
        </ul>
      </div>
    </div>
  );
}

function Pricing({ pricingInfo, eventName }: PricingProps) {
  const { setLineItems, currentUser, setCustomAlert } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  function handleAddToCart(item: any) {
    setLineItems((prevItems: any) => {
      const updatedItems = { ...prevItems };
      if (updatedItems[item.variantId]) {
        updatedItems[item.variantId].quantity += 1;
      } else {
        updatedItems[item.variantId] = {
          quantity: 1,
          name: [eventName, item.name],
          price: +item.price,
          variantId: item.variantId,
        };
      }
      return updatedItems;
    });
  }

  return (
    <div>
      <SectionHeader
        subtitle="Pricing"
        header="Ticket Information"
        description="Review the different ticket types and select the best option for you."
      />
      <table className="border-b border-slate-700 w-full">
        {/* <thead>
            <tr>
              <th className="border-t border-slate-700 py-4">Ticket Type</th>
              <th className="border-t border-slate-700 py-4">Price</th>
              <th className="border-t border-slate-700 py-4">Action</th>
            </tr>
          </thead> */}
        <tbody>
          {pricingInfo && pricingInfo.length > 0 ? (
            pricingInfo.map((item) => {
              return (
                <tr key={"pricing-item-" + item.variantId} className="text-sm sm:text-base border-t border-slate-700">
                  <td className="py-5 ">{item.name}</td>
                  <td className="">${item.price}</td>
                  <td className="text-right relative">
                    <button
                      className="border btn rounded-none bg-white border-slate-700 text-slate-900 p-2 hover:bg-white hover:border-slate-800 hover:shadow-md"
                      type="button"
                      onClick={() => {
                        if (
                          currentUser &&
                          Object.keys(currentUser).length > 0
                        ) {
                          handleAddToCart(item);
                        } else {
                          setCustomAlert(
                            <p>
                              Please{" "}
                              <button
                                type="button"
                                onClick={(event) => {
                                  setCustomAlert(null);
                                  navigate("/login");
                                }}
                                className="underline font-bold"
                              >
                                login
                              </button>{" "}
                              first.
                            </p>
                          );
                        }
                      }}
                    >
                      Add to Cart
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <p>Loading ...</p>
          )}
        </tbody>
      </table>
    </div>
  );
}




