export const conferenceIDASS2024 = {
  "53482411884923": {
    name: "Standard (Non-member)",
    price: 450,
    variantId: "53482411884923",
  },
  "53482411491707": {
    name: "Standard (IEEE Member)",
    price: 400,
    variantId: "53482411491707",
  },
  "53482411950459": {
    name: "Student (Non-member)",
    price: 400,
    variantId: "53482411950459",
  },
  "53482412114299": {
    name: "Student (IEEE Member)",
    price: 350,
    variantId: "53482412114299",
  },
  "44968524710187": {
    name: "test",
    price: 1.1,
    variantId: "44968524710187",
  },
};
