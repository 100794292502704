import React from 'react';
import styles from "./Footer.module.css";
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// import logo from "../../assets/media/company/cizhou-logo.png";
const termsPDF = require("../../assets/pdf/Events House Terms & Conditions.pdf");
const privacyPDF = require("../../assets/pdf/Events House Privacy Policy.pdf");
const registrationPDF = require("../../assets/pdf/Events House Registration and Refund Policy.pdf");
const Footer: React.FC = () => {

    return (
        <footer className={`${styles.footerContainer} border-t px-20 py-10 flex flex-col items-center`}>
            {/* <NavLink to="/"><img className="h-8" src={logo} alt="company logo" /></NavLink> */}
            <NavLink to="/" className="font-extrabold text-xl">Events House</NavLink>
            {/* <ul className='flex gap-5 mt-4'>
                <li><HashLink smooth to="/#events">Upcoming Events</HashLink></li>
                <li><HashLink smooth to="/#past-events">Past Events</HashLink></li>
                <li><HashLink smooth to="/#contact-us">Contact</HashLink></li>
            </ul> */}
            <p className='mt-5'><a href="mailto:contact@eventshouse.net">contact@eventshouse.net</a></p>
            <div className='flex text-center leading-tight flex-col sm:flex-row items-center gap-2 sm:gap-4 text-sm text-slate-500 mt-4 sm:mt-2'>
                <a href={termsPDF} target="_blank">Terms & Conditions</a>
                <a href={privacyPDF} target="_blank">Privacy Policy</a>
                <a href={registrationPDF} target="_blank">Registration and Refund Policy</a>
            </div>
            <p className='text-sm opacity-50 mt-10 text-left'>&copy; 2024 Events House</p>
            <div className='text-xs opacity-30 text-center'>
                <p>Events House is a trading name of New Vision Consultants Ltd</p>
                <p>Registered in England and Wales. Company No. 14663147</p>
            </div>
        </footer>
    );
};

export default Footer;
