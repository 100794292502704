import React, { useContext } from "react";
import { GlobalContext } from "../../context/globalContext";
import CloseIcon from "../Icon/CloseIcon";

export default function AlertWindow({
  children,
}: {
  children: React.ReactNode;
}) {
  const { setCustomAlert } = useContext(GlobalContext);
  return (
    <div className="fixed top-[50vh] left-[50vw] translate-x-[-50%] translate-y-[-50%] bg-white p-20 border border-slate-700 z-50">
      <p
        className="font-mono absolute top-3 right-3 hover:cursor-pointer hover:bg-slate-100 p-2 rounded-full"
        onClick={() => {
          setCustomAlert(null);
        }}
      >
        <CloseIcon width={15} color="rgb(51 65 85)" />
      </p>
      {children}
    </div>
  );
}
