import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { GlobalContext } from "../../context/globalContext";
import { logoutUserAPI } from "../../api/auth";
import { LineItemType } from "../../types/lineItem";
// import logo from "../../assets/media/company/cizhou-logo.png";

const Header: React.FC = () => {
  const { currentUser, setCurrentUser, showCart, setShowCart, lineItems } =
    useContext(GlobalContext);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  async function handleUserLogout() {
    try {
      setIsLoggingOut(true);
      const response = await logoutUserAPI();
      if (response && response.ok) {
        setCurrentUser(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoggingOut(false);
    }
  }

  return (
    <header
      className={`w-full flex justify-between py-2 px-4 pr-8 fixed top-0 bg-white shadow-sm z-[99999]`}
    >
      {/* <ul className="flex gap-8 items-center font-semibold">
        <li>
          <HashLink smooth to="/checkout/wechat-alipay">
            WeChat Alipay
          </HashLink>
        </li>
        <li>
          <HashLink smooth to="/checkout">
            Checkout
          </HashLink>
        </li>
      </ul> */}
      {/* <ul className="flex gap-8 items-center font-semibold">
        <li>
          <HashLink smooth to="/#events">
            Events
          </HashLink>
        </li>
        <li>
          <HashLink smooth to="/#contact-us">
            Contact
          </HashLink>
        </li>
      </ul> */}
      <NavLink to="/" className="flex items-center gap-3">
        {/* <img className="max-h-12" src={logo} about='Cizhou logo' /> */}
        <p className="font-bold">Events House</p>
      </NavLink>
      <ul
        className="flex gap-6 items-center font-normal"

      >
        {currentUser && Object.keys(currentUser).length > 0 ? (
          <>
            <li className="relative"
              onMouseEnter={() => {
                setShowUserDropdown(true);
              }}
              onMouseLeave={() => {
                setShowUserDropdown(false);
              }}
            >
              <p>{currentUser.firstName}</p>
              {showUserDropdown && (
                <div className="absolute top-0 right-[-20px] pt-[53px]">
                  <div className="flex flex-col gap-3 border border-gray-100 rounded-sm p-4 bg-white font-medium">
                    <NavLink
                      to="/user/orders"
                      className="border-b border-gray-100 pb-3 hover:underline pr-10 text-nowrap"
                    >
                      Past orders
                    </NavLink>
                    {/* <NavLink
                    to="/user/personal-details"
                    className="pb-3 hover:underline"
                  >
                    My account
                  </NavLink>
                  <NavLink
                    to="/user/preferences"
                    className="border-b border-gray-100 pb-3 hover:underline"
                  >
                    Preferences
                  </NavLink> */}
                    <p
                      onClick={handleUserLogout}
                      className=" hover:underline hover:cursor-pointer"
                    >
                      {isLoggingOut ? "Logging out ..." : "Logout"}
                    </p>
                  </div>
                </div>
              )}

            </li>
            <li
              onClick={() => {
                setShowCart(!showCart);
              }}
              className="hover:cursor-pointer flex items-center"
              id="header-cart"
            >
              <span>Cart</span>
              {Object.keys(lineItems).length !== 0 &&
                <span className="border border-secondary-dark/50 text-secondary-dark/50 rounded-full text-sm font-normal w-5 h-5 flex items-center justify-center ml-2">
                  {/* {Object.keys(lineItems).length} */}
                  {Object.values(lineItems as Record<string, LineItemType>).reduce((sum, item) => sum + item.quantity, 0) as number}
                </span>
              }
            </li>
          </>
        ) : (
          <>
            <li>
              <NavLink to="/signup">Sign Up</NavLink>
            </li>
            <li>
              <NavLink
                className="bg-slate-700 text-white px-4 py-1 rounded-sm"
                to="/login"
              >
                Login
              </NavLink>
            </li>
          </>
        )}


      </ul>
    </header>
  );
};

export default Header;
