import { API_ENDPOINT } from ".";
import axios from 'axios';

export const loginUserAPI = async (obj: any) => {
  // TODO: backend code needs to handle
  // 1. check password
  // 2. register with shopify and get access token
  // 3. set persistent login or not

  try {
    const response = await fetch(`${API_ENDPOINT}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const registerUserAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/register`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...obj, mode: "email" }),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const updateUserAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/update-user`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};


export const logoutUserAPI = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const checkUserSession = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/check-session`, {
      method: "GET",
      credentials: "include",
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// DELAY
export const resetPasswordAPI = async (obj: any) => {
  // TODO: backend needs to handle:
  // 1. {oobCode, confirmPassword}
  // 2. raise error if not successful

  try {
    const response = await fetch(`${API_ENDPOINT}/reset-password`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// TESTED, now integrated into /register
export const sendEmailCodeAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/send-email-verification`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...obj, mode: "email" }),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// DELAY
export const sendPasswordCodeAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/send-email-verification`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...obj, mode: "password" }),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// TESTED
export const verifyEmailCodeAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/verify-email`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...obj, mode: "email" }),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// DELAY
export const verifyPasswordCodeAPI = async (obj: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/verify-email`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...obj, mode: "password" }),
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
