import { API_ENDPOINT } from ".";
import { FUSION_CODE_API_ENDPOINT } from ".";
export const getUserOrdersAPI = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/orders`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching user orders:", err);
    throw err; // Re-throw the error so it can be handled by the caller
  }
};

interface FusionCodeParams {
  client_id: string;
  sign: string;
  amount?: number;
  payment_channel?: "alipay" | "wechat";
  valid_time?: number;
  reference?: string;
  out_trade_no?: string;
  notify_url?: string;
  order_title?: string;
  order_detail?: string;
  currency?: string;
  device_id?: string;
  operator_id?: string;
  extended_info?: string;
  passback_info?: string;
  trans_currency?: string;
}

export const getFusionCodeAPI = async (params: any) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/payment-qrcode`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching fusion code:", err);
    throw err;
  }
};
