import React from "react";
import "./FormInput.css";

export default function FormInput(props) {
  return (
    <label
      className={`relative ${props.input.class ? props.input.class : ""
        } form-txt`}
    >
      <p>{props.input.label}</p>
      <img src={props.input.img} alt="" className="absolute" />
      <input
        className="bg-white"
        type={props.input.type}
        value={props.input.value}
        placeholder={props.input.placeholder ? props.input.placeholder : ""}
        onChange={(e) => {
          props.input.update(e.target.value);
        }}
        required={props.input.required ? true : false}
      />
    </label>
  );
}
