import React, { createContext, useState, useEffect } from "react";
import App from "../app/App";
import { checkUserSession } from "../api/auth";
import { User } from "../types/user";

interface LineItem {
  name: string;
  price: number;
  variantId: string;
  quantity: number;
}

export const GlobalContext = createContext<any>({});

export const GlobalContainer = (props: any) => {
  const [isMobile, setIsMobile] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(true);

  const [analytics, setAnalytics] = useState(
    localStorage.getItem("cookieAnalytics")
      ? +localStorage.getItem("cookieAnalytics")!
      : 0
  );
  const [preference, setPreference] = useState(
    localStorage.getItem("cookiePreference")
      ? +localStorage.getItem("cookiePreference")!
      : 0
  );
  const [privacy, setPrivacy] = useState(
    localStorage.getItem("cookiePrivacy")
      ? +localStorage.getItem("cookiePrivacy")!
      : 0
  );

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [showCart, setShowCart] = useState(false);

  const [lineItems, setLineItems] = useState<Record<number, LineItem>>(() => {
    const storedItems = localStorage.getItem("lineItems");
    return storedItems ? JSON.parse(storedItems) : {};
  });
  const [customAlert, setCustomAlert] = useState<React.ReactNode>();

  function handleCheckout(method: string) {
    if (!currentUser || Object.keys(currentUser).length === 0) return;

    let finalURL = "";
    if (method === "other-payments") {
      // finalURL = "https://checkout.eventshouse.net/other/";
      finalURL = "https://41f212.myshopify.com/cart/";
    } else if (method === "wechat-alipay") {
      finalURL =
        process.env.NODE_ENV === "development"
          ? "/checkout/wechat-alipay/"
          : "/checkout/wechat-alipay/";
      // process.env.NODE_ENV === "development"
      //   ? "/checkout/wechat-alipay/"
      //   : "https://checkout.wechat-alipay.eventshouse.net/";
    }

    const lineItemsParam = Object.entries(lineItems)
      .map(([_, item]) => `${item.variantId}:${item.quantity}`)
      .join(",");

    if (method === "wechat-alipay") {
      finalURL += `?items=${lineItemsParam}`;
    } else {
      finalURL += lineItemsParam;
    }

    // https://checkout.eventshouse.net/wechat-alipay/44810540253483:1,44810544152875:1,44810547331371:2
    // https://checkout.eventshouse.net/other/44810540253483:1,44810544152875:1,44810547331371:2
    // https://41f212.myshopify.com/cart/44810540253483:1,44810544152875:1,44810547331371:2

    // Object.entries(lineItems).forEach(([key, item]: [string, any], index) => {
    //   finalURL += (index > 0 ? "," : "") + item.variantId + ":" + item.quantity;
    // });

    // FOR SHOPIFY CHECKOUT WEBHOOK, TODO: test it//
    if (method === "other-payments") {
      finalURL += "?checkout[email]=" + currentUser.email;
      finalURL += "&note=" + currentUser.id;
    }
    window.location.href = finalURL;
  }

  function setAnalyticsFunc(n: any) {
    setAnalytics(n);
    props.setAnalytics(n);
  }

  useEffect(() => {
    const handleClickOutsideCart = (event: MouseEvent) => {
      let targetElement = event.target as HTMLElement;

      while (targetElement) {
        if (targetElement.id === "cart" || targetElement.id === "header-cart") {
          return;
        }
        targetElement = targetElement.parentElement as HTMLElement;
      }

      if (showCart) {
        setShowCart(false);
      }
    };

    document.addEventListener("click", handleClickOutsideCart);

    return () => {
      document.removeEventListener("click", handleClickOutsideCart);
    };
  }, [showCart]);

  useEffect(() => {
    localStorage.setItem("lineItems", JSON.stringify(lineItems));
  }, [lineItems]);

  useEffect(() => {
    // Function to check if the user is logged in when the app loads
    const verifyUserSession = async () => {
      try {
        const response = await checkUserSession();
        if (response && response.ok) {
          // Check if response status is OK
          const userData = await response.json(); // Parse JSON body
          if (userData.user) {
            const user = userData.user;
            setCurrentUser(user); // Update state with user data and message
            setLineItems(() => {
              const storedItems = localStorage.getItem("lineItems");
              return storedItems ? JSON.parse(storedItems) : {};
            });
          } else {
            setCurrentUser(null); // Clear user state if not logged in
            setLineItems({});
          }
        }
      } catch (error) {
        console.error("Session check failed:", error);
      }
    };

    verifyUserSession();
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth < 430);
    setIsSmallScreen(window.innerWidth < 640);

    function handleResize() {
      setIsMobile(window.innerWidth < 430);
      setIsSmallScreen(window.innerWidth < 640);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = customAlert ? "hidden" : "visible";
    document.querySelector("main")!.style.opacity = customAlert ? "0.3" : "1";
  }, [customAlert]);

  return (
    <GlobalContext.Provider
      value={{
        isMobile,
        setIsMobile,
        isSmallScreen,
        setIsSmallScreen,
        analytics,
        setAnalyticsFunc,
        preference,
        setPreference,
        privacy,
        setPrivacy,
        currentUser,
        setCurrentUser,
        lineItems,
        setLineItems,
        showCart,
        setShowCart,
        customAlert,
        setCustomAlert,
        handleCheckout,
      }}
    >
      <App />
    </GlobalContext.Provider>
  );
};
