import React, { useState } from 'react';
// import { auth, firebaseConfig } from '../../config/firebase-config';
// import { sendCustomPasswordResetEmail } from "firebase/auth";
import FormInput from '../../components/Form/FormInput/FormInput';
// import { passwordReset } from "../../config/firebase-config";
import { NavLink, useNavigate } from 'react-router-dom';
import { resetPasswordAPI, sendPasswordCodeAPI, verifyPasswordCodeAPI } from '../../api/auth';

const emailIcon = require("../../assets/media/icon/email1.png");
const lockIcon = require("../../assets/media/icon/lock1.png");

function ForgotPassword() {
    // const [email, setEmail] = useState('');
    // const [emailMessage, setEmailMessage] = useState(false);
    const [codeIsSent, setCodeIsSent] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");

    const [email, setEmail] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState('email'); // 'email', 'code', 'password'
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();

    //     try {
    //         await resetPasswordAPI(email);
    //         setEmailMessage(true);
    //     } catch (error: any) {
    //         if (error.code === 'auth/user-not-found') {
    //             alert('User not found, try again!');
    //             setEmail('');
    //         }
    //     }
    // };

    const handleSendCode = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setMessage('');
        setIsSendingCode(true);

        try {
            const response = await sendPasswordCodeAPI({ email });
            if (response && response.status === 200) {
                setCodeIsSent(true);
                setStep('code');
                setMessage('Reset code sent to your email.');
            } else {
                setError("Send verification failed. Please try again.");
            }
        } catch (err) {
            setError('Failed to send reset code. Please try again.');
        } finally {
            setIsSendingCode(false);
        }
    };



    const handleVerifyCode = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setMessage('');
        try {
            const response = await verifyPasswordCodeAPI({ email, code: resetCode });
            if (response && response.status === 200) {
                setStep('password');
                setMessage('Code verified. Please enter your new password.');
            } else {
                setError("Code verification failed. Please try again.");
            }
        } catch (err) {
            setError('Invalid reset code. Please try again.');
        }
    };

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setMessage('');
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        try {
            const response = await resetPasswordAPI({ email, newPassword });
            if (response && response.status === 200) {
                setCodeIsSent(true);
                setStep('code');
                setMessage('Password reset successful. Redirecting to login...');
                setTimeout(() => navigate('/login'), 3000);
            } else {
                setError("Send verification failed. Please try again.");
            }
        } catch (err) {
            setError('Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="login-container forget-password-container max-w-[400px] mx-auto">
            <h2 className='text-2xl font-bold mb-10'>Forgot Password</h2>
            {step === 'email' && (
                <form onSubmit={handleSendCode} className='flex flex-col gap-2'>
                    <FormInput
                        input={{
                            class: "static",
                            label: "Please enter your email:",
                            type: "email",
                            value: email,
                            img: emailIcon,
                            placeholder: "example@email.com",
                            update: (value: string) => setEmail(value)
                        }}
                    />
                    <button className='btn btn-xs' type="submit">Send Code</button>
                </form>
            )}
            {step === 'code' && (
                <form onSubmit={handleVerifyCode} className='flex flex-col gap-2'>
                    <p>For Email: {email}</p>
                    <FormInput
                        input={{
                            class: "static",
                            label: "Enter reset code:",
                            type: "text",
                            value: resetCode,
                            img: lockIcon,
                            update: (value: string) => setResetCode(value)
                        }}
                    />
                    <button className='btn btn-xs' type="submit">Verify Code</button>
                </form>
            )}
            {step === 'password' && (
                <form onSubmit={handleResetPassword} className='flex flex-col gap-2'>
                    <FormInput
                        input={{
                            class: "static",
                            label: "New Password:",
                            type: "password",
                            value: newPassword,
                            img: lockIcon,
                            update: (value: string) => setNewPassword(value)
                        }}
                    />
                    <FormInput
                        input={{
                            class: "static",
                            label: "Confirm New Password:",
                            type: "password",
                            value: confirmPassword,
                            img: lockIcon,
                            update: (value: string) => setConfirmPassword(value)
                        }}
                    />
                    <button className='btn btn-xs' type="submit">Reset Password</button>
                </form>
            )}
            {message && <p className="text-green-600 mt-2">{message}</p>}
            {error && <p className="text-red-600 mt-2">{error}</p>}
            <NavLink className={"text-center block text-sm mt-4"} to="/login" style={{ textDecoration: "underline", color: "var(--dark-blue)" }}>Back to login</NavLink>
        </div>
    );


    // const sendEmailVerification = async (
    //     e: React.MouseEvent<HTMLButtonElement>
    // ) => {
    //     e.preventDefault();
    //     setIsSendingCode(true);
    //     try {
    //         const response = await sendPasswordCodeAPI({ email, });

    //         if (response && response.status === 200) {
    //             setCodeIsSent(true);
    //         } else {
    //             setErrorMessage("Send verification failed. Please try again.");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setIsSendingCode(false);
    //     }
    // };

    // return (
    // <div className="forgot-password-page max-w-[400px] mx-auto">
    //     {
    //         emailMessage
    //             ?
    //             <h3>The Email has been sent; <br />Please check your Inbox!</h3>
    //             :
    //             <div className="login-container forget-password-container">
    //                 <h2 className='text-2xl font-bold mb-10'>Forgot password</h2>
    //                 <form id="form-login" onSubmit={handleSubmit} className='flex flex-col gap-2'>
    //                     <FormInput input={{ class: "static", label: "Please enter your email:", type: "txt", value: email, img: emailIcon, placeholder: "example@email.com", update: (value: string) => { setEmail(value); } }} />
    //                     <button className='btn btn-xs' id="login-button" type="submit" style={{ marginBottom: "10px" }}>Send Code</button>
    //                 </form>
    //                 <NavLink className={"text-center block text-sm mt-4"} to="/login" style={{ textDecoration: "underline", color: "var(--dark-blue)" }}>Back to login</NavLink>
    //             </div>
    //     }
    // </div>
    // );
}

export default ForgotPassword;
