import React, { useState, useRef, useContext } from 'react';
import './Cookie.css';
import { GlobalContext } from '../../context/globalContext';
import { NavLink } from 'react-router-dom';

const privacyPDF = require("../../assets/pdf/Events House Privacy Policy.pdf");

export default function Cookie() {
    const [preferenceOpen, setPreferenceOpen] = useState(false);
    const {
        analytics, setAnalyticsFunc,
        preference, setPreference,
        privacy, setPrivacy } = useContext(GlobalContext);

    const cookieRef = useRef<HTMLDivElement | null>(null);

    function savePreferences(analytics: any, preference: any) {
        localStorage.setItem('cookieAnalytics', analytics);
        localStorage.setItem('cookiePreference', preference);
        // if (preference) {
        localStorage.setItem('cookiePrivacy', "1");
        setPrivacy(1);
        setPreference(preference);
        setAnalyticsFunc(analytics);
        // }
        if (cookieRef.current) { cookieRef.current.style.display = 'none'; }
    }

    if (privacy) {
        return;
    }

    return (
        <div className="banner-cookie-container" ref={cookieRef}>
            <div className='cookie-banner'>
                <h2>This site uses cookies</h2>
                <p>We use cookies and similar technologies to personalise content and analyse website performance. To learn more, read our <a href={privacyPDF} target='_blank'>Privacy Policy</a>.</p>
                <div className='buttons flex flex-col sm:flex-row gap-2'>
                    <button className="self-stretch sm:self-auto" onClick={() => { savePreferences(1, 1); }}><p className="mx-auto">Agree All</p></button>
                    <button className="self-stretch sm:self-auto" onClick={() => { savePreferences(0, 0); }}><p className="mx-auto">Reject All</p></button>
                    <button className="" onClick={() => { setPreferenceOpen(!preferenceOpen); }}><p className="mx-auto flex items-center">Preferences
                        {preferenceOpen ?
                            " -"
                            :
                            " +"}
                    </p>
                    </button>
                </div>
            </div>
            <div className={`manage-cookies-container ${preferenceOpen ? "open" : ""}`}>
                <ul className={`manage-cookies`}>
                    <li>
                        <div>
                            <h3>Necessary</h3>
                            <p style={{ fontWeight: "400" }}>Required</p>
                        </div>
                        <p>Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or checking out to register.</p>
                    </li>
                    <li>
                        <div>
                            <h3>Analytics</h3>
                            <Toggle checked={analytics} setChecked={setAnalyticsFunc} />
                        </div>
                        <p>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</p>
                    </li>
                    <li>
                        <div>
                            <h3>Preference</h3>
                            <Toggle checked={preference} setChecked={setPreference} />
                        </div>
                        <p>These cookies remember user preferences to enhance the user's browsing experience.</p>
                    </li>
                </ul>
                <button className='save' onClick={() => { savePreferences(analytics, preference); }}>Save Preferences</button>
            </div>
        </div>
    );
}

function Toggle(props: any) {
    return (
        <div className="toggle-custom">
            <div className={`${props.checked ? "yes" : "no"} layer`} onClick={() => {
                props.setChecked(props.checked ? 0 : 1);
            }}>
                <div className={`knob`}></div>
            </div>
        </div>
    );
}