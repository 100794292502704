import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import getSymbolFromCurrency from "currency-symbol-map";
import logo from "../assets/media/logos/company/events-house-logo.png";

export function HTMLtoPDF(content, filename, setLoading, size) {
  html2canvas(content, { scale: 5 }).then((canvas) => {
    const imageData = canvas.toDataURL();
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      compress: true,
    });

    const imgWidth = doc.internal.pageSize.getWidth() * size;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    doc.addImage(
      imageData,
      "PNG",
      0,
      0,
      imgWidth,
      imgHeight,
      undefined,
      "FAST"
    );
    doc.save(`${filename}.pdf`);
    setLoading(false);
  });
}

export function createPDF(head, body, filename) {
  const doc = new jsPDF();
  doc.autoTable({
    head: head,
    body: body,
  });
  doc.save(`${filename}.pdf`);
}

export function createReceiptPDF(filename, info) {
  const doc = new jsPDF({
    compress: true,
  });

  // Define variables for layout control
  let y = 30;
  let interval = 7;
  let padding = 20;
  let xPositions = {
    leftMost: padding,
    midLeft: 100,
    midRight: 140,
    rightMost: doc.internal.pageSize.getWidth() - padding,
  };

  // Add padding to the document
  y = padding;
  xPositions.leftMost = padding;
  xPositions.rightMost = doc.internal.pageSize.getWidth() - padding;

  // Add logo with original ratio
  const logoWidth = 30;
  const logoHeight = (2204 / 3891) * logoWidth;

  try {
    doc.addImage(logo, 'PNG', xPositions.leftMost, 20, logoWidth, logoHeight);
  } catch (error) {
    console.error('Error adding logo to PDF:', error);
    // Optionally, you can add a placeholder text instead of the logo
    doc.text('LOGO', xPositions.leftMost, 20);
  }



  // "INVOICE" at top right
  doc.setFontSize(16);
  doc.setFont("helvetica", "normal");
  doc.setTextColor(10, 55, 107);
  doc.text("INVOICE", xPositions.rightMost, 20, { align: "right" });
  y += interval * 2;
  // Move `y` below the logo
  // "Conference" at top right
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text("Conference", xPositions.rightMost, y, { align: "right" });
  y += interval;


  // Map info.conference to each line, all right aligned
  doc.setFont("helvetica", "normal");
  info.conference.forEach(line => {
    doc.text(line, xPositions.rightMost, y, { align: "right" });
    y += interval * 0.8;
  });

  y = 80;



  // "BILL TO" on the left
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("BILL TO", xPositions.leftMost, y);
  doc.setFont("helvetica", "normal");
  y += interval;
  const billingAddressLines = Object.values(info.billingAddress).filter(line => line);
  const customerInfo = [
    ["Customer:", info.name],
    ["Invoice No:", info.orderNumber],
    ["Payment date:", info.orderDate],
    ["Payment method:", info.paymentChannel],
  ];

  const maxLines = Math.max(billingAddressLines.length, customerInfo.length);

  for (let i = 0; i < maxLines; i++) {
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    if (billingAddressLines[i]) {
      doc.text(billingAddressLines[i], xPositions.leftMost, y);
    }
    if (customerInfo[i]) {
      const [label, value] = customerInfo[i];
      const displayValue = value !== undefined ? value.toString() : 'N/A';
      doc.setFont("helvetica", "normal");
      doc.text(label, xPositions.midRight - 10, y, { align: "left" }); // Left aligned label with wider width
      doc.setFont("helvetica", "bold");
      doc.text(displayValue, xPositions.rightMost, y, { align: "right" }); // Right aligned value
    }
    y += interval * 0.8;
  }
  // y += interval;


  // Table section using autoTable with layout control
  const tableWidth = doc.internal.pageSize.getWidth() - 2 * padding;
  doc.autoTable({
    startY: y,
    head: [['DESCRIPTION', 'QUANTITY', `UNIT PRICE (${info.currency})`, `AMOUNT (${info.currency})`]],
    body: Object.keys(info.lineItems).map((variantId) => {
      const item = info.lineItems[variantId];
      return [
        item.name,
        item.quantity.toString(),
        `${getSymbolFromCurrency(info.currency)}${parseFloat(item.unitPriceIncl).toFixed(2)}`,
        `${getSymbolFromCurrency(info.currency)}${parseFloat(item.subtotal).toFixed(2)}`
      ];
    }),
    theme: 'plain', // Use plain theme to customize borders manually
    styles: {
      fontSize: 10,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      cellPadding: 3,
      halign: 'center',
      valign: 'middle',
    },
    headStyles: {
      fontSize: 8,
      fillColor: [245, 245, 245],
      textColor: [0, 0, 0],
      // Do not set halign here to allow columnStyles to override
    },
    columnStyles: {
      0: {
        cellWidth: tableWidth * 0.4,
        halign: 'left', // Align first column to the left
      },
      1: { cellWidth: tableWidth * 0.2 },
      2: { cellWidth: tableWidth * 0.2 },
      3: { cellWidth: tableWidth * 0.2 }
    },
    // Remove the drawCell function
    // drawCell: function (data) { ... },

    // Move the border-drawing code to didDrawCell
    didDrawCell: function (data) {
      const doc = data.doc;
      const cell = data.cell;
      const row = data.row;
      const col = data.column;
      const lineWidth = 0.1; // Thinner border

      doc.setDrawColor(192, 192, 192); // Lighter gray color for borders
      doc.setLineWidth(lineWidth);

      // Draw bottom border (horizontal line at the bottom of the cell)
      doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);

      // Draw top border for the first row
      if (row.index === 0) {
        doc.line(cell.x, cell.y, cell.x + cell.width, cell.y);
      }

      // Draw left border for the first column
      if (col.index === 0) {
        doc.line(cell.x, cell.y, cell.x, cell.y + cell.height);
      }

      // Draw right border for the last column
      if (col.index === data.table.columns.length - 1) {
        doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height);
      }
    },
    didParseCell: function (data) {
      // Ensure body cells have a white background
      if (data.section === 'body') {
        data.cell.styles.fillColor = [255, 255, 255];
      }

      // Left-align the first column in the header
      if (data.section === 'head' && data.column.index === 0) {
        data.cell.styles.halign = 'left';
      }
    },
    margin: { left: xPositions.leftMost },
    tableWidth: tableWidth
  });

  // Adjust y position based on the table's end position
  y = doc.lastAutoTable.finalY + interval;

  // Total section as a table
  const totalInfo = [
    [`TOTAL (${info.currency})`, `${getSymbolFromCurrency(info.currency)}${parseFloat(info.amount).toFixed(2)}`],
    [`TOTAL (${info.transCurrency})`, `${getSymbolFromCurrency(info.transCurrency)}${parseFloat(info.transAmount).toFixed(2)}`],
  ];

  doc.autoTable({
    startY: y,
    body: totalInfo,
    theme: 'plain',
    styles: {
      fontSize: 10,
      fillColor: [255, 255, 255], // White background for rows
      textColor: [10, 55, 107], // Dark blue text for rows
      lineColor: [192, 192, 192], // Gray border
      lineWidth: 0.1, // Border width
      fontStyle: 'bold' // Bold text
    },
    didParseCell: function (data) {
      if (data.row.index === 0) {
        data.cell.styles.fillColor = [10, 55, 107]; // Blue background for first row
        data.cell.styles.textColor = [255, 255, 255]; // White text for first row
      } else if (data.row.index === 1) {
        data.cell.styles.fillColor = [255, 255, 255]; // White background for second row
        data.cell.styles.lineColor = [192, 192, 192]; // Gray border for second row
      }
    },
    columnStyles: {
      0: { cellWidth: tableWidth * 0.2, halign: 'left' }, // Description column, left aligned
      1: { cellWidth: tableWidth * 0.2, halign: 'right' }  // Amount column, right aligned
    },
    margin: { left: xPositions.rightMost - tableWidth * 0.4 },
    tableWidth: tableWidth * 0.4
  });

  // Adjust y position based on the table's end position
  y = doc.lastAutoTable.finalY + interval;

  // Footer section, all center aligned
  doc.setFontSize(8.8);
  doc.setTextColor(211, 211, 211); // Lighter gray text
  const pageHeight = doc.internal.pageSize.getHeight();
  const footerY = pageHeight - 20; // Position footer 20 units from the bottom

  doc.text(
    "Events House is a trading name of New Vision Consultants Ltd. Registered in England and Wales. Company No. 14663147",
    doc.internal.pageSize.getWidth() / 2,
    footerY,
    { align: "center" }
  );
  doc.text(
    "contact@eventshouse.net",
    doc.internal.pageSize.getWidth() / 2,
    footerY + 5,
    { align: "center" }
  );
  doc.text(
    "www.eventshouse.net",
    doc.internal.pageSize.getWidth() / 2,
    footerY + 10,
    { align: "center" }
  );

  // Add a thinner line above the footer
  doc.setLineWidth(0.1);
  doc.setDrawColor(192, 192, 192); // Set line color to light gray
  doc.line(xPositions.leftMost, footerY - 5, xPositions.rightMost, footerY - 5);

  // Save the PDF
  doc.save(`${filename}.pdf`);
}

// export function createReceiptPDF(filename, info) {
//   let y = 30;
//   let interval = 7;
//   let xValues = {
//     leftMost: 20,
//     totalLeft: 100,
//     totalRight: 175,
//     quantity: 122,
//     // unitPriceExcl: 94,
//     // vatRate: 122,
//     unitPriceIncl: 142,
//     subtotal: 174,
//   };
//   const maxWidthForProductName = 80;

//   const doc = new jsPDF({
//     orientation: "p",
//     unit: "mm",
//     format: "a4",
//     compress: true,
//   });

//   // RECEIPT TITLE AND NUMBER //
//   doc.setFontSize(16);
//   doc.text("Receipt", doc.internal.pageSize.getWidth() - 20, 20, {
//     align: "right",
//   });
//   doc.setFontSize(12);
//   doc.text(info.receiptNumber, doc.internal.pageSize.getWidth() - 20, 30, {
//     align: "right",
//   });

//   // BILLING ADDRESS //
//   doc.setFont(undefined, "normal");
//   y += interval;
//   if (info.billingAddress) {
//     const { name, address1, address2, city, country, zip } = info.billingAddress;
//     if (name) {
//       doc.text(xValues.leftMost, y, name);
//       y += interval;
//     }
//     if (address1) {
//       doc.text(xValues.leftMost, y, address1);
//       y += interval;
//     }
//     if (address2) {
//       doc.text(xValues.leftMost, y, address2);
//       y += interval;
//     }
//     if (city) {
//       doc.text(xValues.leftMost, y, city);
//       y += interval;
//     }
//     if (country) {
//       doc.text(xValues.leftMost, y, country);
//       y += interval;
//     }
//     if (zip) {
//       doc.text(xValues.leftMost, y, zip);
//       y += interval;
//     }
//   }
//   y += 10;

//   // ORDER INFORMATION //
//   _drawHorizontalLine(y, 0.25);
//   y += interval;
//   doc.setFont(undefined, "bold");
//   doc.text(xValues.leftMost, y, "Order Information");
//   y += interval;
//   doc.setFont(undefined, "normal");
//   doc.text(xValues.leftMost, y, "Customer:");
//   doc.text(xValues.quantity, y, info.name);
//   y += interval;
//   doc.text(xValues.leftMost, y, "Order Date:");
//   doc.text(xValues.quantity, y, info.orderDate.toString());
//   y += interval;
//   doc.text(xValues.leftMost, y, "Order #:");
//   doc.text(xValues.quantity, y, info.orderNumber.toString());
//   y += interval;

//   _drawHorizontalLine(y, 0.25);
//   y += interval;

//   // RECEIPT DETAILS //
//   doc.setFont(undefined, "bold");
//   doc.text(xValues.leftMost, y, "Receipt Details");
//   y += interval;
//   doc.setFont(undefined, "normal");
//   doc.text(xValues.leftMost, y, "Description");
//   doc.text(xValues.quantity, y, "Qty");
//   // doc.text(xValues.unitPriceExcl, y, 'Unit price');
//   // doc.text(xValues.vatRate, y, 'VAT rate');
//   doc.text(xValues.unitPriceIncl, y, "Unit Price");
//   doc.text(xValues.subtotal, y, "Subtotal");
//   y += interval;
//   // doc.setFontSize(10);
//   // doc.text(xValues.unitPriceExcl + doc.getTextWidth("Unit price") / 2, y, '(excl. VAT)', { align: "center" });
//   // doc.text(xValues.unitPriceIncl + doc.getTextWidth("Unit price") / 2, y, '(incl. VAT)', { align: "center" });
//   // doc.text(xValues.subtotal + doc.getTextWidth("Unit price") / 2, y, '(incl. VAT)', { align: "center" });
//   // y += interval + 3;
//   doc.setFontSize(12);
//   Object.values(info.products).forEach((product) => {
//     const lines = doc.splitTextToSize(product.name, maxWidthForProductName);
//     let totalHeight = 0;
//     lines.forEach((line) => {
//       const lineDimensions = doc.getTextDimensions(line);
//       totalHeight += lineDimensions.h;
//     });
//     doc.text(xValues.leftMost, y, lines.toString());
//     doc.text(
//       xValues.quantity + doc.getTextWidth("Qty") / 2,
//       y,
//       product.quantity.toString(),
//       { align: "center" }
//     );
//     // doc.text(xValues.unitPriceExcl + doc.getTextWidth("Unit price") / 2, y, `$${product.unitPriceExcl}`, { align: "center" });
//     // doc.text(xValues.vatRate + doc.getTextWidth("VAT rate") / 2, y, product.vatRate, { align: "center" });
//     doc.text(
//       xValues.unitPriceIncl + doc.getTextWidth("Unit price") / 2,
//       y,
//       `${getSymbolFromCurrency(info.currency)}${product.unitPriceIncl}`,
//       { align: "center" }
//     );
//     doc.text(
//       xValues.subtotal + doc.getTextWidth("Subtotal") / 2,
//       y,
//       `${getSymbolFromCurrency(info.currency)}${product.subtotal}`,
//       { align: "center" }
//     );
//     y += interval + totalHeight;
//   });

//   // TOTAL PRICE AND VAT //
//   _drawHorizontalLine(y, 0.25);
//   y += interval + 7;
//   doc.setFont(undefined, "bold");
//   doc.setFontSize(14);
//   doc.text(xValues.totalLeft, y, "Total");
//   doc.text(doc.internal.pageSize.getWidth() - 20, y, `${getSymbolFromCurrency(info.currency)}${info.amount}`, {
//     align: "right",
//   });
//   doc.text(doc.internal.pageSize.getWidth() - 20, y, `${getSymbolFromCurrency(info.transCurrency)}${info.transAmount}`, {
//     align: "right",
//   });
//   //   doc.text(`$${info.totalIncl}`, doc.internal.pageSize.getWidth() - 20, y, {
//   //     align: "right",
//   //   });
//   y += interval + 3;
//   doc.setFont(undefined, "normal");
//   // doc.setFontSize(12);
//   // doc.text(xValues.totalLeft, y, 'VAT subtotal (20%)');
//   // doc.text(`$${info.totalVAT}`, doc.internal.pageSize.getWidth() - 20, y, { align: "right" });
//   // y += interval;
//   // doc.text(xValues.totalLeft, y, 'Item subtotal (excl. VAT)');
//   // doc.text(`$${info.totalExcl}`, doc.internal.pageSize.getWidth() - 20, y, { align: "right" });
//   // y += interval;

//   // FOOTER //
//   _drawHorizontalLine(270, 0.25);
//   doc.setTextColor(190, 190, 190);
//   doc.setFontSize(10);
//   const text = "Events House";
//   const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
//   const pageWidth = doc.internal.pageSize.getWidth();
//   const xCenter = (pageWidth - textWidth) / 2;
//   doc.text(text, xCenter, 277);
//   doc.save(`${filename}.pdf`);

//   function _drawHorizontalLine(y, width) {
//     doc.setDrawColor(230, 230, 230);
//     doc.setLineWidth(width);
//     doc.line(xValues.leftMost, y, 190, y);
//   }
// }
