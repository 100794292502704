// src/pages/PaymentTimeoutPage.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';

const PaymentTimeoutPage: React.FC = () => {
    return (
        <div className="flex flex-col mt-20 items-center justify-center h-full bg-white p-8">
            <h1 className="text-3xl font-bold mb-6 text-secondary-dark">
                Payment Timeout
            </h1>
            <p className="text-lg text-center max-w-[600px] mb-2">
                Your payment session has timed out. Please try again or contact our support team if you need assistance.
            </p>
            <NavLink
                to="/checkout"
                className="bg-secondary-dark text-white px-6 py-2 rounded-md font-bold mt-10 hover:bg-secondary-dark/80"
            >
                Return to Checkout
            </NavLink>
        </div>
    );
};

export default PaymentTimeoutPage;