import EventPage from "./EventPage";
import img from "../../assets/media/background/idass.jpg";
import map from "../../assets/media/background/idass-map.jpg";
import { conferenceIDASS2024 } from "../../data/productData";
import { GlobalContext } from "../../context/globalContext";
import { useContext } from "react";

export default function EventIDASS() {
  const { currentUser } = useContext(GlobalContext);
  return (
    <EventPage
      eventName="2024 International Conference on Intelligent Data Analysis and Sustainable Systems (IDASS)"
      tags={["Sustainable Systems", "Intelligent Technologies", "Networking"]}
      img={img}
      basicInfo={{
        eventDescriptionShort: [
          "IDASS gathers experts to explore data analytics and sustainable systems solutions.",
        ],
        eventDescriptionLong: [
          "The 2024 International Conference on Intelligent Data Analytics and Sustainable Systems (IDASS 2024) will be held at 20-22 October, 2024 at Qingdao city, Shandong, China.",
          "IDASS is a large and prominent platform for international researchers, professionals and industry experts to come together and explore the solutions and the intersection of intelligent data analytics technologies and sustainable systems, encouraging technological discussions and potential cooperation.",
        ],
        date: "2024-10-20 to 2024-10-22",
        time: "",
        location: "Ocean University of China, Qingdao, China",
        status: "Open for Registration",
        link: "https://www.idass.org.cn",
      }}
      keynoteInfo={[
        {
          name: "John Doe",
          desc: "John Doe is a renowned industry leader with over 20 years of experience in the tech field. He has been instrumental in the development of several groundbreaking technologies.",
          keynoteTitle: "The Future of Technology: A Look Ahead",
        },
        {
          name: "Jane Smith",
          desc: "Jane Smith is a visionary in the field of artificial intelligence. Her research and insights have shaped the way we understand and utilize AI today.",
          keynoteTitle:
            "Artificial Intelligence: Transforming the World as We Know It",
        },
      ]}
      scheduleInfo={[
        {
          date: "2023-09-15",
          schedule: [
            {
              time: "10:00 AM",
              location: "Hall A",
              title: "Opening Keynote",
              desc: "An opening keynote by industry leader John Doe on the impact of technology.",
            },
            {
              time: "12:00 PM",
              location: "Room 101",
              title: "Workshop: IoT Security",
              desc: "Hands-on workshop on securing Internet of Things devices.",
            },
          ],
        },
        {
          date: "2023-09-16",
          schedule: [
            {
              time: "09:00 AM",
              location: "Hall B",
              title: "Panel Discussion: The Future of AI",
              desc: "A panel discussion on the future of artificial intelligence and its implications.",
            },
            {
              time: "11:00 AM",
              location: "Room 102",
              title: "Workshop: Data Science for Everyone",
              desc: "An interactive workshop on the basics of data science for attendees of all skill levels.",
            },
          ],
        },
        {
          date: "2023-09-17",
          schedule: [
            {
              time: "10:00 AM",
              location: "Hall C",
              title: "Product Launch: NextGen Smartwatch",
              desc: "Launch event for the latest smartwatch technology in wearable devices.",
            },
            {
              time: "01:00 PM",
              location: "Room 103",
              title: "Seminar: Cybersecurity Trends",
              desc: "A seminar on the latest trends and best practices in cybersecurity.",
            },
          ],
        },
      ]}
      pricingInfo={Object.values(
        currentUser && currentUser.email === "contact@eventshouse.net"
          ? conferenceIDASS2024
          : Object.fromEntries(
            Object.entries(conferenceIDASS2024).filter(
              ([key]) => key !== "44968524710187"
            )
          )
      )}
      contactInfo={{
        email: "contact@techconference.com",
        phone: "123-456-7890",
        office: "123 Tech Lane, San Francisco, CA",
        mapImg: map,
      }}
    />
  );
}
