import React, { useContext, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { GlobalContext } from '../../context/globalContext';

import UserPageOrders from './UserPageOrders';
import UserPagePersonalDetails from './UserPagePersonalDetails';
import UserPagePreferences from './UserPagePreferences';
// import UserPageNameBadge from './UserPageNameBadge';
// import UserPageAdmin from './UserPageAdmin';




export default function UserPage({ page }: { page: string; }) {
    const { currentUser } = useContext(GlobalContext);
    return (
        <div className={"user-page-container"}>
            {currentUser
                ?
                <>
                    <UserSideMenu />
                    <div className="divider"></div>
                    <div className="user-page-content">
                        {returnUserPage(page)}
                    </div>
                </>
                :
                <p className="please-login"> Please<NavLink to="/signup">
                    <span className="material-symbols-outlined">login</span>
                    sign up
                </NavLink>
                    or
                    <NavLink to="/login">
                        <span className="material-symbols-outlined">how_to_reg</span>
                        login
                    </NavLink>
                    first.
                </p>
            }
        </div>
    );
}

function UserSideMenu() {
    const { currentUser, currentUserInfo } = useContext(GlobalContext);
    const location = useLocation();

    const logoutUser = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        try {
            // TODO: signout user here
            // await auth.signOut();
            if (!currentUser) {
                console.error("didn't logout user successfully, firebase error.");
            }
        } catch (err) {
            console.error(err);
        }
    };

    function isActive(pathname: string) {
        return location.pathname === pathname;

    };

    return (
        <aside className="user-side-menu-container">
            <h3>My account</h3>
            <ul>
                <li className={isActive("/user/personal-details") ? "active" : ""}>
                    <NavLink to="/user/personal-details">
                        <span className="material-symbols-outlined">account_circle</span>
                        <span>Personal Details</span>
                    </NavLink>
                </li>
                <li className={isActive("/user/badge") ? "active" : ""}>
                    <NavLink to="/user/badge" >
                        <span className="material-symbols-outlined">badge </span>
                        <span>Badge and Tickets</span>
                    </NavLink>
                </li>
                {/* <li className={isActive("/user/my-schedule") ? "active" : ""}>
                    <NavLink to="/user/my-schedule" >
                        <span className="material-symbols-outlined">calendar_month</span>
                        <span>My Schedule</span>
                    </NavLink>
                </li> */}
                <li className={isActive("/user/orders") ? "active" : ""}>
                    <NavLink to="/user/orders" >
                        <span className="material-symbols-outlined">inventory_2</span>
                        <span>Orders</span>
                    </NavLink>
                </li>
                <li className={isActive("/user/preferences") ? "active" : ""}>
                    <NavLink to="/user/preferences" >
                        <span className="material-symbols-outlined">settings</span>
                        <span>Settings</span>
                    </NavLink>
                </li>
                {currentUserInfo && currentUserInfo.admin &&
                    (<li className={isActive("/user/admin") ? "active" : ""}>
                        <NavLink to="/user/admin" >
                            <span className="material-symbols-outlined">table_view</span>
                            <span>Database</span>
                        </NavLink>
                    </li>)
                }
                <li>
                    <a onClick={logoutUser}>
                        <span className="material-symbols-outlined">logout</span>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </aside>
    );
}

function returnUserPage(page: string) {

    switch (page) {
        // case "badge":
        //     return <UserPageNameBadge />;
        case "orders":
            return <UserPageOrders />;
        case "personal-details":
            return <UserPagePersonalDetails />;
        case "preferences":
            return <UserPagePreferences />;
        // case "admin":
        //     return <UserPageAdmin />;
        default:
            return;
    }
}