// TODO: change date from string to Date, check all files

import React, { useState } from "react";
import { sendEmailHostFormAPI } from "../../api/email";
import styles from "./HostFormPage.module.css";

export default function HostFormPage() {
  const [name, setName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDescriptionShort, setEventDescriptionShort] = useState("");
  const [eventDescriptionLong, setEventDescriptionLong] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [files, setFiles] = useState<File[]>([]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // TODO: check fields here

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("contactEmail", contactEmail);
      formData.append("eventName", eventName);
      formData.append("eventDescriptionShort", eventDescriptionShort);
      formData.append("eventDescriptionLong", eventDescriptionLong);
      tags.forEach((tag) => formData.append("tags", tag));
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("time", time);
      formData.append("location", location);
      files.forEach((file) => formData.append("files", file));

      const response = await sendEmailHostFormAPI(formData);
      // TODO: if response status 200 then ...
    } catch (err) {
      console.error(err);
      // TODO: set error message and display
    }
  };
  return (
    <div className={`${styles.formContainer} max-w-[700px] mx-auto mb-40`}>
      <h1 className="font-bold text-xl mb-10">
        Event Information Collection Form
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-2">
        <label>
          Responsible Person:
          <input
            className={styles.textInput}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          Event Name:
          <input
            className={styles.textInput}
            type="text"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
        </label>
        <label>
          Short Description:
          <input
            className={styles.textInput}
            type="text"
            value={eventDescriptionShort}
            onChange={(e) => setEventDescriptionShort(e.target.value)}
          />
        </label>
        <label>
          Long Description:
          <textarea
            className={styles.textInput}
            value={eventDescriptionLong}
            onChange={(e) => setEventDescriptionLong(e.target.value)}
          />
        </label>
        <label>
          Tags: (comma separated)
          <input
            className={styles.textInput}
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value.split(","))}
          />
        </label>
        <label>
          Start Date:
          <input
            className={styles.textInput}
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          Start Time:
          <input
            className={styles.textInput}
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </label>
        <label>
          End Date:
          <input
            className={styles.textInput}
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <label>
          Location:
          <input
            className={styles.textInput}
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </label>
        <label>
          Contact Email:
          <input
            className={styles.textInput}
            type="email"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </label>
        <label>
          Event schedule, keynote, and pricing information <br />
          (PDF only):
          <input
            className="file-input file-input-bordered file-input-sm w-full max-w-xs"
            type="file"
            accept=".pdf"
            multiple
            onChange={(e) =>
              setFiles((prevFiles) =>
                e.target.files
                  ? prevFiles.concat(Array.from(e.target.files))
                  : prevFiles
              )
            }
          />
        </label>
        <ol className="list-decimal ">
          <p className="font-medium">Uploaded Files</p>
          {files.map((file, index) => (
            <li key={index} className="flex justify-between ">
              <span>- {file.name}</span>
              <button
                type="button"
                onClick={() => setFiles(files.filter((_, i) => i !== index))}
              >
                Delete
              </button>
            </li>
          ))}
        </ol>
        <p>Or send it to example@email.com when the information is ready.</p>
        <button className="btn mt-10" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}
