import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/globalContext";

export default function UserPagePreferences() {
  const [deleteText, setDeleteText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { currentUser } = useContext(GlobalContext);

  async function handleDeleteUser(
    e: React.MouseEvent<HTMLButtonElement>,
    number: number
  ) {
    e.preventDefault();

    // TODO: get current user here
    const user = undefined;
    // const user = auth.currentUser;
    if (currentUser && currentUser.email && deleteText === "DELETE") {
      // TODO: delete user here
      // deleteUser(user).then(async () => {
      //     await saveDeletedInFirebase(user, {
      //         name: user.displayName
      //     });
      //     await auth.signOut();
      // }).catch((error) => {
      //     console.error(error.message);
      //     if (error.message === "Firebase: Error (auth/requires-recent-login).") {
      //         setErrorMessage("Please login again to delete your account.");
      //         setLoginAgain(true);
      //         setDeleteText("");
      //     } else {
      //         setErrorMessage(`Unable to delete user, please contact ${emailAddress.tech}.`);
      //     }
      // });
    }
  }
  return (
    <div className={"user-page-preferences-container"}>
      <div className="user-page-title">
        <h1>Settings</h1>
      </div>
      <h3>DELETE account</h3>
      <p>
        You will automatically be logged out after the deletion of your account,
        the lost data may not be recoverable.{" "}
      </p>
      <div className="user-page-delete-container">
        <p>Please type "DELETE": </p>
        <label>
          {""}
          <input
            type="text"
            value={deleteText}
            onChange={(e) => {
              setDeleteText(e.target.value);
            }}
          />
        </label>
        <button
          onClick={(e) => {
            handleDeleteUser(e, 1);
          }}
          disabled={deleteText !== "DELETE"}
        >
          DELETE
        </button>
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
}
